import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { RangeSlider } from "rsuite";
import "rsuite/dist/rsuite.css";

const Price = ({ setFilterPrice, filterPrice }) => {
  const [searchParams] = useSearchParams();
  console.log("filterPrice", filterPrice);

  // Extract the 'category' parameter from the search parameters
  const selectedJobRateMax = searchParams.get("jobRateMax");
  const selectedJobRateMin = searchParams.get("jobRateMin");

  const [show, setShow] = useState(true);

  useEffect(() => {
    setFilterPrice({
      min: filterPrice?.min ?? selectedJobRateMin,
      max: filterPrice?.max ?? selectedJobRateMax,
    });
  }, [
    setFilterPrice,
    selectedJobRateMin,
    selectedJobRateMax,
    filterPrice?.min,
    filterPrice?.max,
  ]);

  const handleRanges = (value) => {
    console.log("value", value);

    setFilterPrice({ min: value[0], max: value[1] });
  };

  const handleMinRangeChange = (e) => {
    const value = parseInt(e.target.value);
    console.log("dddddd", value);

    setFilterPrice({ ...filterPrice, min: value });
    if (value > filterPrice?.max) {
      setFilterPrice({ ...filterPrice, max: value });
    }
  };

  const handleMaxRangeChange = (e) => {
    const value = parseInt(e.target.value);
    console.log("dddddd---------", value);
    setFilterPrice({ ...filterPrice, max: value });
    if (value < filterPrice?.min) {
      setFilterPrice({ ...filterPrice, min: value });
    }
  };

  return (
    <div className="card mb20 pb10 mt-0">
      <div className={`${show ? "active" : ""}`} id="heading0">
        <h4>
          <button
            className="btn btn-link ps-0"
            type="button"
            onClick={() => setShow((prev) => !prev)}
          >
            Price
          </button>
        </h4>
      </div>
      <div
        id="collapse0"
        className={`collapse ${show ? "show" : ""}`}
        aria-labelledby="heading0"
        data-parent="#accordionExample"
        style={{
          width: "16rem",
          padding: "20px",
        }}
      >
        <RangeSlider
          min={1}
          max={100000}
          defaultValue={[1, 100000]}
          onChange={handleRanges}
          className="custom-slider"
        />
        <br />
        <div className="text-center">
          <input
            type="number"
            className="amount"
            value={filterPrice?.min}
            style={{ width: "90px", height: "41px" }}
            onChange={handleMinRangeChange}
          />
          <span className="fa-sharp fa-solid fa-minus mx-2 dark-color"></span>
          <input
            type="number"
            className="amount2"
            value={filterPrice?.max}
            style={{ width: "90px", height: "41px" }}
            onChange={handleMaxRangeChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Price;
