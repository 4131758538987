import React, { useEffect, useRef } from "react";

// import Toast from "@components/Common/Alert";

// import {
//   checkUploadedFile,
//   getPresignedUrl,
//   uploadFileToS3,
// } from "@framework/s3-pre-signed/document-upload";
// import { MAX_CHUNK_SIZE } from "@framework/utils/allowedFileImageType";
// import { getFileExtension } from "@framework/utils/document";
// import { ERRORS } from "@framework/utils/error-message";

// import { TINY_EDITOR_LOADER_SVG } from "./constant";

// import Styles from "./editor.module.scss";

import { Editor } from "@tinymce/tinymce-react";

const TextEditor = ({
  onChange,
  tabId,
  initialValue,
  handleEditorInit,
  isDisabled = false,
}) => {
  const editorRef = useRef(null);

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        if (mutation.type === "childList") {
          const dialog = document.querySelector(".tox-dialog");
          if (dialog) {
            disableSourceInput();
          }
        }
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    // Clean up observer on component unmount
    return () => {
      observer.disconnect();
    };
  }, []);

  const disableSourceInput = () => {
    const sourceInput = document.querySelector(
      "input[type='url'][role='combobox']"
    );
    if (sourceInput) {
      sourceInput.setAttribute("disabled", "disabled");
      sourceInput.addEventListener("input", (event) => event.preventDefault());
      sourceInput.addEventListener("paste", (event) => event.preventDefault());
    }
  };

  //   const showLoader = () => {
  //     const dialogBody = document.querySelector(".tox-dialog__body ");
  //     const browseButton = document.querySelector(".tox-browse-url");
  //     const saveButton = document.querySelector('button[aria-label="Save"]');
  //     const cancelButton = document.querySelector(".tox-button--secondary");
  //     if (dialogBody) {
  //       dialogBody.className = Styles.file_modal_content;
  //       const loader = document.createElement("div");
  //       loader.className = Styles.tiny_loader;
  //       loader.innerHTML = TINY_EDITOR_LOADER_SVG;
  //       dialogBody.appendChild(loader);
  //     }

  //     if (browseButton) {
  //       browseButton.style.display = "none";
  //     }

  //     // Disable Save and Cancel buttons
  //     if (saveButton) saveButton.setAttribute("disabled", "disabled");
  //     if (cancelButton) cancelButton.setAttribute("disabled", "disabled");
  //   };

  //   const hideLoader = () => {
  //     const loader = document.querySelector(`.${Styles.tiny_loader}`);
  //     const saveButton = document.querySelector('button[aria-label="Save"]');
  //     const browseButton = document.querySelector(".tox-browse-url");
  //     const cancelButton = document.querySelector(".tox-button--secondary");
  //     if (loader) {
  //       loader.remove();
  //       // Enable Save and Cancel buttons
  //       if (saveButton) saveButton.removeAttribute("disabled");
  //       if (cancelButton) cancelButton.removeAttribute("disabled");
  //     }

  //     // Restore the "Browse files" button
  //     if (browseButton) {
  //       browseButton.style.display = "inline-block";
  //     }
  //   };

  const handleEditorChange = () => {
    if (editorRef.current) {
      const content = editorRef.current.getContent();
      onChange(tabId, content);
    }
  };

  return (
    <Editor
      tinymceScriptSrc="https://cdn.tiny.cloud/1/cf8sqm8sabigokrsu80ffmz0skc75sbf6nun6wgbd85eqfmz/tinymce/6/tinymce.min.js"
      //   apiKey='cf8sqm8sabigokrsu80ffmz0skc75sbf6nun6wgbd85eqfmz'
      onInit={(evt, editor) => {
        editorRef.current = editor;
        handleEditorInit && handleEditorInit(editor);
      }}
      value={initialValue}
      init={{
        height: 500,
        selector: "textarea",
        menubar: false,
        // automatic_uploads: false,
        // file_picker_types: "file image media",
        paste_data_images: true,
        // images_upload_base_path: "/some/basepath",
        relative_urls: true,
        file_picker_callback: (callback) => {
          const input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("accept", "image/*");
          input.setAttribute("class", "upload_field");
          //   input.onchange = async () => {
          //     const [file] = input.files;
          //     const totalChunks = Math.ceil(file?.size / MAX_CHUNK_SIZE);
          //     const chunks = [];

          //     for (let i = 0; i < totalChunks; i++) {
          //       const start = i * MAX_CHUNK_SIZE;
          //       const end = Math.min(start + MAX_CHUNK_SIZE, file.size);
          //       chunks.push(file?.slice(start, end));
          //     }
          //     try {
          //       if (file?.size > MAX_CHUNK_SIZE) {
          //         Toast.fire({
          //           icon: "error",
          //           title: ERRORS.FILE_SIZE_5_MB,
          //         });
          //       } else {
          //         showLoader();
          //         const baseFileName = file.name.replace(/\.[^.]+$/, "");
          //         const sanitizedFileName = `${baseFileName.replace(
          //           /[^a-zA-Z0-9-_]/g,
          //           ""
          //         )}.${getFileExtension(file.name)}`;
          //         const presignedUrl = await getPresignedUrl(
          //           sanitizedFileName,
          //           totalChunks
          //         );
          //         const uploadPromises = [];
          //         for (let index = 0; index < totalChunks; index++) {
          //           const chunk = chunks[index];
          //           const parsedUrl = new URL(presignedUrl.url);
          //           parsedUrl.searchParams.delete("X-Amz-Signature");
          //           parsedUrl.searchParams.delete("partNumber");
          //           const partNumber = index + 1;
          //           const signature = presignedUrl.signature[`${partNumber}`];
          //           const preSignedChunkUrl = `${parsedUrl.toString()}&partNumber=${partNumber}&X-Amz-Signature=${signature}`;
          //           const formData = new FormData();
          //           formData.append("file", chunk);

          //           const response = await uploadFileToS3(
          //             preSignedChunkUrl,
          //             chunk
          //           )
          //             .then(() => ({
          //               success: true,
          //               chunk,
          //               index,
          //               fileSize: file.size,
          //               fileName: presignedUrl?.file_name,
          //               url: preSignedChunkUrl,
          //               uploadedFileId: presignedUrl?.upload_id,
          //             }))
          //             .catch((error) => {
          //               console.error("Error while uploading chunk:", error);
          //               return {
          //                 success: false,
          //                 chunk,
          //                 index,
          //                 fileSize: file.size,
          //                 fileName: presignedUrl?.file_name,
          //                 url: preSignedChunkUrl,
          //                 uploadedFileId: presignedUrl?.upload_id,
          //               };
          //             });
          //           uploadPromises.push(response);
          //         }

          //         const responses = await Promise.all(uploadPromises);

          //         const failedChunks = responses.filter(
          //           (response) => !response?.success
          //         );
          //         if (failedChunks.length === 0) {
          //           const payload = {
          //             file_name: presignedUrl?.file_name,
          //             upload_id: presignedUrl?.upload_id,
          //           };
          //           await checkUploadedFile(payload);
          //         }
          //         const reader = new FileReader();
          //         file.reader = reader;
          //         file.presignedUrl = presignedUrl;
          //         const loadEndPromise = new Promise((resolve) => {
          //           reader.onloadend = () => {
          //             hideLoader();
          //             callback(presignedUrl?.url?.split("?")[0], {
          //               alt: presignedUrl?.file_name,
          //             });
          //             resolve();
          //           };
          //         });
          //         file instanceof Blob && reader?.readAsDataURL(file);
          //         await loadEndPromise;
          //       }
          //     } catch (error) {
          //       console.error("Error while uploading the file", error);
          //       hideLoader();
          //       Toast.fire({
          //         icon: "error",
          //         title:
          //           error.message ||
          //           formatMessage({ id: "errorWhileUploadingFile" }),
          //       });
          //     }
          //   };
          input.click();
        },
        plugins: [
          "advlist",
          "autolink",
          "lists",
          "link",
          "charmap",
          "anchor",
          "searchreplace",
          "visualblocks",
          "fullscreen",
          //   "image",
          "insertdatetime",
          "media",
          "table",
          "preview",
          "wordcount",
          "paste",
        ],
        toolbar:
          "undo redo | blocks | " +
          "bold italic forecolor | alignleft aligncenter " +
          "alignright alignjustify bullist numlist removeformat",

        content_style:
          "body { font-family:Helvetica,Arial,ssans-serif; font-size:14px }",
      }}
      onEditorChange={handleEditorChange}
      disabled={isDisabled}
    />
  );
};

export default TextEditor;
