import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

export function InformationModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Information
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text" style={{ fontWeight: "500", fontSize: "18px" }}>
          {props.message ||
            "To access the photographer's full profile, kindly sign in or sign up."}
        </p>
        {!props.isAuth && (
          <div className="d-flex justify-content-end">
            <Link
              className="mr15 ud-btn add-joining home20-join-btn text-white"
              to="/signup/job-poster"
              state={{ redirectUrl: `/photographers/${props?.currentData}` }}
              style={{ padding: "8px 30px" }}
            >
              Sign Up
            </Link>
            <Link
              className="mr15 ud-btn add-joining home20-join-btn text-white"
              to="/login/job-poster"
              state={{ redirectUrl: `/photographers/${props?.currentData}` }}
              style={{ padding: "8px 30px" }}
            >
              Sign In
            </Link>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}
