function BellIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      viewBox="0 0 216 216"
      width="40"
      height="40"
      role="img"
    >
      <defs>
        <style>{`.st0 { fill: #fff; }`}</style>
      </defs>
      <path d="M113.17,25.78l8.41,1.06c34.2,5.6,61.87,33.06,67.58,67.29l1.06,7.78v12.53c-.53,1.72-.47,3.74-.73,5.53-5.29,35.95-34.3,64.92-70.43,69.58-2.05.26-4.24.2-6.21.69h-9.95c-1.85-.5-3.96-.43-5.89-.69-36.27-4.81-65.73-34.17-70.47-70.5-.25-1.93-.2-4.04-.69-5.9.15-3.4-.2-6.89,0-10.28C28.23,62.07,62.12,28.16,102.89,25.78h10.27ZM147.84,141.72v-4.98c0-.72-9.36-8.5-9.89-10.35-1.13-16.04,3.74-34.48-7.93-47.63-3.85-4.33-8.91-7.12-14.47-8.65-.27-3.11.31-5.78-1.93-8.35-2.9-3.32-8.56-3.3-11.32.2-2.13,2.69-1.52,4.98-1.8,8.15-6.37,1.71-12.12,5.27-16.04,10.61-9.75,13.25-5.22,30.32-6.36,45.67-.52,1.84-9.89,9.63-9.89,10.35v4.98h79.62ZM117.98,146.54h-19.91c.29,13.4,19.63,13.37,19.91,0Z" />
      <path
        className="st0"
        d="M147.84,141.72h-79.62v-4.98c0-.72,9.36-8.5,9.89-10.35,1.13-15.35-3.39-32.42,6.36-45.67,3.93-5.34,9.67-8.89,16.04-10.61.28-3.18-.33-5.46,1.8-8.15,2.76-3.5,8.43-3.52,11.32-.2,2.24,2.57,1.65,5.24,1.93,8.35,5.56,1.53,10.63,4.31,14.47,8.65,11.67,13.15,6.8,31.59,7.93,47.63.52,1.84,9.89,9.63,9.89,10.35v4.98Z"
      />
      <path
        className="st0"
        d="M117.98,146.54c-.28,13.37-19.61,13.4-19.91,0h19.91Z"
      />
    </svg>
  );
}

export default BellIcon;
