/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
// import '../../assets/css/ud-custom-spacing.css';
import { Link } from "react-router-dom";
import { Status, UserTypes } from "../../constants/constant.js";
import { useSelector } from "react-redux";
import { selectAccessToken } from "../../features/auth/authSlice.js";
import { useRef } from "react";
import "./Navbar.css";
import { logoutUser } from "../../common/logOut.js";
import { useLocation } from "react-router-dom/dist/index.js";
import { getUserInfo } from "../../common/getUserInfo.js";
import { UseIsDesktopOrLaptop } from "../../utils/Responsive/Responsive.js";
import {
  getNotificationList,
  getUnseenNotificationList,
  jobInvitations,
} from "../../API/UserApi.js";
import { getToken } from "../../common/getToken.js";
import NotificationsDropdown from "../../shared/NotificationDropdown/NotificationDropdown.jsx";
import { getChatCount } from "../../API/ChatApi.js";
import { ErrorToaster } from "../../shared/Toasters/toaster.js";
import BellIcon from "../../utils/Icons/BellIcon.jsx";
import MessagesIcon from "../../utils/Icons/MessagesIcon.jsx";
import HamburgerIcon from "../../utils/Icons/HamburgerIcon.jsx";

const Navbar = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showMobileDropdown, setShowMobileDropdown] = useState(false);
  const [unseenNotifications, setUnseenNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showNotificationDropdown, setShowNotificationDropdown] =
    useState(false);
  const [notifications, setNotifications] = useState([]);
  const [showMobileSideMenu, setShowMobileSideMenu] = useState(false);
  const [showSignInOption, setShowSignInOption] = useState(false);
  const [chatCount, setChatCount] = useState(null);
  const [jobInviteCount, setJobInviteCount] = useState(null);
  const fileInputRef = useRef(null);
  const isDesktopOrLaptop = UseIsDesktopOrLaptop();
  const location = useLocation();
  const pathName = location.pathname;
  const token = useSelector(selectAccessToken) || getToken();
  const dropdownRef = useRef(null);
  const notificationDropdownRef = useRef(null);
  const userInfo = getUserInfo();

  const fetchUnseenNotifications = async () => {
    try {
      const res = await getUnseenNotificationList();
      setUnseenNotifications(res);
    } catch (error) {
      console.error("error", error?.response?.data?.message);
    }
  };

  const getChatsCount = async () => {
    try {
      const res = await getChatCount();
      setChatCount(res || {});
    } catch (error) {}
  };

  const myJobInvites = async () => {
    try {
      const res = await jobInvitations();
      setJobInviteCount(res?.invitations || []);
    } catch (error) {
      ErrorToaster(error?.message);
    }
  };

  useEffect(() => {
    if (token) {
      fetchUnseenNotifications();
      getChatsCount();
      myJobInvites();
      const intervalId = setInterval(() => {
        fetchUnseenNotifications();
        getChatsCount();
      }, 500);

      // Cleanup function to clear the interval when the component unmounts or when token changes
      return () => clearInterval(intervalId);
    }
  }, [token]);

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current?.contains(event.target)) {
      setShowMobileSideMenu(false);
      setShowDropdown(false);
      setShowMobileDropdown(false);
    }
    if (
      notificationDropdownRef.current &&
      !notificationDropdownRef.current?.contains(event.target)
    ) {
      setShowNotificationDropdown(false);
    }
  };
  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const fetchNotifications = async () => {
    setIsLoading(true);
    try {
      const res = await getNotificationList();
      setNotifications(res);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("error", error?.response?.data?.message);
    }
  };

  const handleNotificationClick = () => {
    setShowNotificationDropdown((prev) => {
      const newValue = !prev;
      if (newValue) {
        fetchNotifications(); // Fetch notifications when dropdown is opened
      }
      return newValue;
    });
  };
  const PhotographersComponent = () => {
    return (
      <>
        <div className="align-items-center mt20">
          <Link className="login-info fz16" to="/jobs">
            <span className="d-none d-xl-inline-block">Home</span>
          </Link>
        </div>
        {userInfo?.profileStatus === Status.COMPLETED && (
          <>
            <span className="mx-3"></span>{" "}
            <div className="align-items-center mt20">
              <Link className="login-info fz16" to="/job-invites">
                <span className="d-none d-xl-inline-block">Job Invites</span>
                {(jobInviteCount || []).length > 0 && (
                  <span
                    className="badge rounded-pill position-absolute end-345"
                    style={{
                      top: "22px",
                      backgroundColor: "#5BBB7B",
                    }}
                  >
                    {jobInviteCount.length}
                  </span>
                )}
              </Link>
            </div>
            <span className="mx-3"></span>{" "}
            <div className="align-items-center mt20">
              <Link className="login-info fz16" to="/photographer-jobs">
                <span className="d-none d-xl-inline-block">My Jobs</span>
              </Link>
            </div>
            {/* <span className="mx-3"></span>{' '}
            <div className="align-items-center mt20">
              <Link
                className="login-info fz16"
                to={`/photographers/${userInfo?._id}`}>
                <span className="d-none d-xl-inline-block">My Details</span>
              </Link>
            </div> */}
          </>
        )}
      </>
    );
  };

  const JobPosterComponent = () => {
    return (
      <>
        <div className="align-items-center mt20">
          <Link className="login-info fz16" to="/photographers">
            <span className="d-none d-xl-inline-block">Home</span>
          </Link>
        </div>
        {userInfo?.profileStatus === Status.COMPLETED && (
          <>
            <span className="mx-3"></span>{" "}
            <div className="align-items-center mt20">
              <Link className="login-info fz16" to="/create-job">
                <span className="d-none d-xl-inline-block">Post A Job</span>
              </Link>
            </div>
            <span className="mx-3"></span>{" "}
            <div className="align-items-center mt20">
              <Link className="login-info fz16" to="/my-jobs">
                <span className="d-none d-xl-inline-block">My Jobs</span>
              </Link>
            </div>
          </>
        )}
      </>
    );
  };

  const AdminComponent = () => {
    return (
      <>
        <div className="align-items-center mt20">
          <Link className="login-info fz16" to="/dashboard">
            <span className="d-none d-xl-inline-block">Home</span>
          </Link>
        </div>
      </>
    );
  };

  const handleSignInClick = () => {
    setShowDropdown((prev) => !prev);
  };

  return (
    <>
      {isDesktopOrLaptop ? (
        <>
          <header className="header-nav nav-innerpage-style at-home20 main-menu border-0">
            <nav className="posr">
              <div className="container-fluid custom-container posr">
                <div
                  className="row align-items-center justify-content-between"
                  style={{ padding: "13px" }}
                >
                  {!!userInfo || !!token ? (
                    <>
                      <div className="col-auto px-0 px-xl-3">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="logos">
                            <Link
                              className="header-logo logo1"
                              to={
                                ["photographer", "agency"].includes(
                                  userInfo?.userType
                                )
                                  ? "/jobs"
                                  : "/photographers"
                              }
                            >
                              <img
                                src="/assets/images/topshot-logo-90mm.png"
                                alt="Header Logo"
                                style={{
                                  width: "100px",
                                  borderRadius: "5px",
                                }}
                              />
                            </Link>
                          </div>
                        </div>
                        <div className="col-auto pe-0 pe-xl-3">
                          <div className="d-flex align-items-center">
                            <span className="mx-5"></span>{" "}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex col-6 col-lg-auto">
                        {userInfo?.userType === "photographer" ||
                        userInfo?.userType === "agency" ? (
                          <PhotographersComponent />
                        ) : userInfo?.userType === "user" ? (
                          <JobPosterComponent />
                        ) : (
                          <AdminComponent />
                        )}
                        <span className="mx-3"></span>{" "}
                        <div className="align-items-center mt20">
                          <Link
                            className="login-info fz16"
                            to={{
                              pathname: "/messages",
                              state: chatCount,
                            }}
                          >
                            <span className="d-none d-xl-inline-block">
                              Messages
                            </span>
                            {Object.values(chatCount || {}).length > 0 && (
                              <span
                                className="badge rounded-pill position-absolute end-345"
                                style={{
                                  top: "22px",
                                  backgroundColor: "#5BBB7B",
                                }}
                              >
                                {Object.values(chatCount || {}).reduce(
                                  (acc, curr) => acc + curr,
                                  0
                                )}
                              </span>
                            )}
                          </Link>
                        </div>
                        <span className="mx-3"></span>{" "}
                        <div className="align-items-center mt20">
                          <div className="d-none d-sm-block">
                            <div
                              className="login-info fz16 show"
                              type="button"
                              aria-expanded="false"
                              ref={notificationDropdownRef}
                              // data-bs-toggle="dropdown"
                              onClick={handleNotificationClick}
                            >
                              <span className="d-none d-xl-inline-block">
                                Notification
                              </span>
                              {unseenNotifications?.length > 0 && (
                                <span
                                  className="badge rounded-pill position-absolute end-345"
                                  style={{
                                    top: "22px",
                                    backgroundColor: "#5BBB7B",
                                  }}
                                >
                                  {unseenNotifications?.length}
                                </span>
                              )}
                            </div>
                            <NotificationsDropdown
                              notifications={notifications}
                              showNotificationDropdown={
                                showNotificationDropdown
                              }
                              setShowNotificationDropdown={
                                setShowNotificationDropdown
                              }
                              isLoading={isLoading}
                            />
                          </div>
                        </div>
                        <span className="mx-3"></span>{" "}
                        {userInfo?.userType === "photographer" ||
                        userInfo?.userType === "agency" ? (
                          <>
                            <div className="align-items-center mt20">
                              <Link
                                className="login-info fz16"
                                to={`/photographers/${userInfo?._id}`}
                              >
                                <span className="d-none d-xl-inline-block">
                                  My Profile
                                </span>
                              </Link>
                            </div>
                            <span className="mx-3"></span>{" "}
                            <div className="align-items-center mt20">
                              <Link className="login-info fz16" to="/profile">
                                <span className="d-none d-xl-inline-block">
                                  Edit Profile
                                </span>
                              </Link>
                            </div>
                          </>
                        ) : (
                          <div className="align-items-center mt20">
                            <Link className="login-info fz16" to="/profile">
                              <span className="d-none d-xl-inline-block">
                                Edit Profile
                              </span>
                            </Link>
                          </div>
                        )}
                        <span className="mx-3"></span>{" "}
                        <div className="text-center text-lg-end header_right_widgets">
                          <ul className="dashboard_dd_menu_list d-flex align-items-center justify-content-center justify-content-sm-end mb-0 p-0">
                            <li className="user_setting">
                              <div className="dropdown" ref={dropdownRef}>
                                <a
                                  onClick={() =>
                                    setShowDropdown((prev) => !prev)
                                  }
                                >
                                  <img
                                    src={
                                      userInfo?.profilePicture
                                        ? userInfo?.profilePicture
                                        : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT18iwsdCCbBfpa50-5BmNa_m_BX087_x1oWQ&usqp=CAU"
                                    }
                                    className="rounded-circle mx-auto"
                                    style={{
                                      width: "60px",
                                      height: "60px",
                                      objectFit: "cover",
                                      borderRadius: "50%",
                                    }}
                                    alt="user.png"
                                  />
                                </a>
                                {showDropdown && (
                                  <div
                                    className={`dropdown-menu ${
                                      showDropdown ? "show" : ""
                                    }`}
                                    style={{
                                      position: "absolute",
                                      inset: "15px auto auto 45px",
                                      margin: "0px",
                                      width: "280px",
                                    }}
                                  >
                                    <div className="user_setting_content">
                                      <Link
                                        className={`dropdown-item ${
                                          pathName === "/profile"
                                            ? "active"
                                            : ""
                                        }`}
                                        to="/profile"
                                      >
                                        <i className="flaticon-photo mr10"></i>
                                        Edit Profile
                                      </Link>

                                      <Link
                                        to="/change-password"
                                        className={`dropdown-item ${
                                          pathName === "/change-password"
                                            ? "active"
                                            : ""
                                        }`}
                                      >
                                        <i className="flaticon-photo mr10"></i>
                                        Change Password
                                      </Link>
                                      {/* <button
                                        className="dropdown-item"
                                        onClick={logoutUser}>
                                        <i className="flaticon-logout mr10"></i>
                                        Logout
                                      </button> */}
                                      <div>
                                        <a
                                          className="dropdown-item"
                                          onClick={logoutUser}
                                        >
                                          <i className="flaticon-logout mr15"></i>
                                          Logout
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-auto px-0 px-xl-3">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="logos">
                            <Link
                              className="header-logo logo1"
                              style={{ width: "60px" }}
                              to="/"
                            >
                              <img
                                src="/assets/images/topshot-logo-90mm.png"
                                alt="Header Logo"
                                style={{ width: "100px", borderRadius: "5px" }}
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto pe-0 pe-xl-3">
                        <div className="d-flex align-items-center">
                          <Link className="login-info fz16" to="/subscriptions">
                            <span className="d-none d-xl-inline-block">
                              Subscription Plans
                            </span>
                          </Link>
                          <span className="mx-2"></span>{" "}
                          <Link className="login-info fz16" to="/photographers">
                            <span className="d-none d-xl-inline-block">
                              Find Photographers
                            </span>
                          </Link>
                          <span className="mx-2"></span>{" "}
                          <Link className="login-info fz16" to="/jobs">
                            <span className="d-none d-xl-inline-block">
                              Find Jobs
                            </span>
                          </Link>
                          <div className="dropdown">
                            <button
                              className="login-info fz16 mr10 home18-sign-btn px30 py-1 bdrs12 ml30 bdr1-dark"
                              style={{
                                height: "40px",
                                borderRadius: "7px",
                                background: "#ffffff",
                                // width: "90px",
                              }}
                              type="button"
                              id="dropdownMenuButton"
                              data-mdb-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Sign In
                            </button>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to={`/login/photographer`}
                                >
                                  Photographer
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to={`/login/job-poster`}
                                >
                                  Job Poster
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to={`/login/agency`}
                                >
                                  Agency
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <div className="dropdown">
                            <button
                              className="ud-btn btn-thm add-joining"
                              style={{ height: "40px", borderRadius: "7px" }}
                              type="button"
                              id="dropdownMenuButton"
                              data-mdb-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Sign Up
                            </button>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to={`/signup/photographer`}
                                >
                                  Photographer
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to={`/signup/job-poster`}
                                >
                                  Job Poster
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to={`/signup/agency`}
                                >
                                  Agency
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </nav>
          </header>
          <div
            className="hiddenbar-body-ovelay"
            style={{ display: "none" }}
          ></div>
        </>
      ) : !!userInfo || !!token ? (
        <div id="page" className="mobilie_header_nav stylehome1">
          <div className="mobile-menu">
            <div className="header bdrb1">
              <div className="menu_and_widgets">
                <div className="mobile_menu_bar d-flex justify-content-between align-items-center">
                  {/* Logo */}
                  <div className="logos">
                    <Link className="header-logo logo1" to="/">
                      <img
                        src="/assets/images/topshot-logo-90mm.png"
                        alt="Header Logo"
                        style={{ width: "100px", borderRadius: "5px" }}
                      />
                    </Link>
                  </div>
                  {/* Sign-in button and hamburger icon */}
                  <div className="text-center text-lg-end header_right_widgets">
                    <ul
                      className="dashboard_dd_menu_list d-flex align-items-center justify-content-center justify-content-sm-end mb-0 p-0"
                      style={{ marginRight: "-15px" }}
                    >
                      <li className="user_setting">
                        <div className="d-flex align-items-center">
                          <div className="d-flex align-items-center">
                            <div
                              className="text-center mr5 text-thm2 dropdown-toggle fz16 mt5"
                              type="button"
                              ref={notificationDropdownRef}
                              // data-bs-toggle="dropdown"
                              onClick={handleNotificationClick}
                            >
                              <BellIcon />
                              {unseenNotifications?.length > 0 && (
                                <span
                                  className="badge rounded-pill position-absolute end-345"
                                  style={{
                                    top: "14px",
                                    right: "99px",
                                    backgroundColor: "#5BBB7B",
                                  }}
                                >
                                  {unseenNotifications?.length}
                                </span>
                              )}
                            </div>
                            {showNotificationDropdown && (
                              <NotificationsDropdown
                                notifications={notifications}
                                showNotificationDropdown={
                                  showNotificationDropdown
                                }
                                setShowNotificationDropdown={
                                  setShowNotificationDropdown
                                }
                                isLoading={isLoading}
                              />
                            )}
                            <Link
                              className="text-center mr5 text-thm2 dropdown-toggle fz16 mt5"
                              to={{
                                pathname: "/messages",
                                state: chatCount,
                              }}
                            >
                              <span>
                                <MessagesIcon />
                              </span>
                              {Object.values(chatCount || {}).length > 0 && (
                                <span
                                  className="badge rounded-pill position-absolute end-345"
                                  style={{
                                    top: "15px",
                                    right: "40px",
                                    backgroundColor: "#5BBB7B",
                                  }}
                                >
                                  {Object.values(chatCount || {}).reduce(
                                    (acc, curr) => acc + curr,
                                    0
                                  )}
                                </span>
                              )}
                            </Link>
                          </div>
                          <div className="dropdown" ref={dropdownRef}>
                            <div
                              onClick={() =>
                                setShowMobileDropdown((prev) => !prev)
                              }
                            >
                              {/* <img
                                src={
                                  userInfo?.profilePicture
                                    ? userInfo?.profilePicture
                                    : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT18iwsdCCbBfpa50-5BmNa_m_BX087_x1oWQ&usqp=CAU"
                                }
                                className="rounded-circle mx-auto"
                                style={{
                                  width: "55px",
                                  height: "55px",
                                  objectFit: "cover",
                                  borderRadius: "50%",
                                  marginTop: "5px",
                                }}
                                alt="user.png"
                              /> */}
                              {/* <a
                             
                              >
                                <img
                                  src="https://creativelayers.net/themes/freeio-html/images/mobile-dark-nav-icon.svg"
                                  alt=""
                                  height={24}
                                />
                              </a> */}
                              {/* <div
                              style={{
                                marginLeft: "10px",
                                paddingLeft: "50px",
                              }}
                              > */}
                              {/* <span
                                className="flaticon-chat"
                                style={{ fontSize: "32px" }}
                              ></span> */}
                              <HamburgerIcon />
                              {/* </div> */}
                            </div>
                            {showMobileDropdown && (
                              <div
                                className={`dropdown-menu ${
                                  showMobileDropdown ? "show" : ""
                                }`}
                                style={{
                                  position: "absolute",
                                  inset: "19px auto auto 37px",
                                  margin: "0px",
                                  width: "280px",
                                }}
                              >
                                <div
                                  className="hsidebar-header"
                                  style={{
                                    borderBottom: "none",
                                    position: "static",
                                    padding: "15px 0 0 0",
                                  }}
                                >
                                  <div
                                    className="sidebar-close-icon"
                                    style={{
                                      top: "1px",
                                      width: "35px",
                                      height: "35px",
                                    }}
                                  >
                                    <span
                                      className="far fa-times"
                                      onClick={() =>
                                        setShowMobileDropdown(false)
                                      }
                                    ></span>
                                  </div>
                                </div>
                                <div className="user_setting_content">
                                  <Link
                                    className={`dropdown-item ${
                                      pathName ===
                                      (userInfo?.userType === "user"
                                        ? "/jobs"
                                        : "/photographers")
                                        ? "active"
                                        : ""
                                    }`}
                                    to={
                                      ["photographer", "agency"].includes(
                                        userInfo?.userType
                                      )
                                        ? "/jobs"
                                        : "/photographers"
                                    }
                                  >
                                    <i className="flaticon-home mr10"></i> Home
                                  </Link>
                                  {userInfo?.userType === "user" &&
                                    userInfo?.profileStatus ===
                                      Status.COMPLETED && (
                                      <>
                                        <Link
                                          className={`dropdown-item ${
                                            pathName === "/create-job"
                                              ? "active"
                                              : ""
                                          }`}
                                          to="/create-job"
                                        >
                                          <i className="flaticon-content mr15"></i>
                                          Post A Job
                                        </Link>
                                      </>
                                    )}
                                  {userInfo?.userType ===
                                    UserTypes.PHOTOGRAPHER && (
                                    <>
                                      <Link
                                        to="/portfolios"
                                        className={`dropdown-item  ${
                                          pathName === "/portfolios"
                                            ? "active"
                                            : ""
                                        }`}
                                      >
                                        <i className="flaticon-document mr15"></i>
                                        Manage Portfolio
                                      </Link>
                                      {userInfo?.profileStatus ===
                                        Status.COMPLETED && (
                                        <>
                                          <Link
                                            to="/my-plan"
                                            className={`dropdown-item ${
                                              pathName === "/my-plan"
                                                ? "active"
                                                : ""
                                            }`}
                                          >
                                            <i className="flaticon-document mr15"></i>
                                            Billing
                                          </Link>
                                          <Link
                                            to="/job-invites"
                                            className={`dropdown-item ${
                                              pathName === "/job-invites"
                                                ? "active"
                                                : ""
                                            }`}
                                          >
                                            <i className="flaticon-briefcase mr15"></i>
                                            Job Invites
                                          </Link>
                                        </>
                                      )}
                                    </>
                                  )}
                                  <Link
                                    className={`dropdown-item ${
                                      pathName ===
                                      (userInfo?.userType === "user"
                                        ? "/my-jobs"
                                        : "/photographer-jobs")
                                        ? "active"
                                        : ""
                                    }`}
                                    to={
                                      userInfo?.userType === "user"
                                        ? "/my-jobs"
                                        : "/photographer-jobs"
                                    }
                                  >
                                    <i className="flaticon-like mr15"></i>My
                                    Jobs
                                  </Link>
                                  <Link
                                    className={`dropdown-item ${
                                      pathName === "/messages" ? "active" : ""
                                    }`}
                                    to={{
                                      pathname: "/messages",
                                      state: { chatCount: chatCount },
                                    }}
                                  >
                                    <i className="flaticon-chat  mr15"></i>
                                    Messages
                                  </Link>
                                  <Link
                                    className={`dropdown-item ${
                                      pathName === "/profile" ? "active" : ""
                                    }`}
                                    to="/profile"
                                  >
                                    <i className="flaticon-briefcase mr15"></i>
                                    Edit Profile
                                  </Link>
                                  {["photographer", "agency"].includes(
                                    userInfo?.userType
                                  ) && (
                                    <Link
                                      className={`dropdown-item ${
                                        pathName ===
                                        `/photographers/${userInfo?._id}`
                                          ? "active"
                                          : ""
                                      }`}
                                      to={`/photographers/${userInfo?._id}`}
                                    >
                                      <i className="flaticon-photo mr10"></i>
                                      My Profile
                                    </Link>
                                  )}
                                  <Link
                                    to="/change-password"
                                    className={`dropdown-item ${
                                      pathName === "/change-password"
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <i className="flaticon-photo mr10"></i>
                                    Change Password
                                  </Link>
                                  <div>
                                    <a
                                      className="dropdown-item"
                                      onClick={logoutUser}
                                    >
                                      <i className="flaticon-logout mr15"></i>
                                      Logout
                                    </a>
                                  </div>
                                  {/* <button
                                    className="dropdown-item"
                                    onClick={logoutUser}>
                                    <i className="flaticon-logout mr10 mb10"></i>
                                    Logout
                                  </button> */}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="posr">
                <div className="mobile_menu_close_btn">
                  <span className="far fa-times"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div id="page" className="mobilie_header_nav stylehome1">
            <div className="mobile-menu">
              <div className="header bdrb1">
                <div className="menu_and_widgets">
                  <div className="mobile_menu_bar d-flex justify-content-between align-items-center">
                    {/* Logo */}
                    <div className="logos">
                      <Link className="header-logo logo1" to="/">
                        <img
                          src="/assets/images/topshot-logo-90mm.png"
                          alt="HeaderLogo"
                          style={{ width: "100px", borderRadius: "5px" }}
                        />
                      </Link>
                    </div>
                    {/* Sign-in button and hamburger icon */}
                    <div className="right-side text-end d-flex align-items-center">
                      {/* Sign-in button dropdown */}
                      <div className="dropdown mr10">
                        <div
                          className="login-info fz16 home18-sign-btn py-1 bdrs12 bdr1-dark position-relative d-flex align-items-center"
                          style={{
                            height: "40px",
                            borderRadius: "7px",
                            background: "#ffffff",
                            width: "90px",
                          }}
                          type="button"
                          onClick={handleSignInClick}
                        >
                          <span className="me-3 m-3">Sign In</span>
                        </div>
                        {showDropdown ? (
                          <ul
                            className="dropdown-menu mt-1 custom-dropdown-menu"
                            style={{ lineHeight: "40px" }}
                          >
                            <li>
                              <Link
                                className="dropdown-item custom-dropdown-item"
                                to={`/login/photographer`}
                                onClick={() => setShowDropdown(false)}
                              >
                                Photographer
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item custom-dropdown-item"
                                to={`/login/job-poster`}
                                onClick={() => setShowDropdown(false)}
                              >
                                Job Poster
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item custom-dropdown-item"
                                to={`/login/agency`}
                                onClick={() => setShowDropdown(false)}
                              >
                                Agency
                              </Link>
                            </li>
                          </ul>
                        ) : null}
                      </div>
                      <div className="dropdown mr10">
                        <button
                          className="login-info fz16 home18-sign-btn py-1 bdrs12 bdr1-dark position-relative d-flex align-items-center"
                          style={{
                            height: "40px",
                            borderRadius: "7px",
                            background: "#ffffff",
                            padding: "0px",
                          }}
                          type="button"
                          onClick={handleSignInClick}
                        >
                          <span className="me-3 m-3">Sign Up</span>
                        </button>
                        {showDropdown ? (
                          <ul
                            className="dropdown-menu mt-1 custom-dropdown-menu"
                            style={{ lineHeight: "40px" }}
                          >
                            <li>
                              <Link
                                className="dropdown-item custom-dropdown-item"
                                to={`/signup/photographer`}
                                onClick={() => setShowDropdown(false)}
                              >
                                Photographer
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item custom-dropdown-item"
                                to={`/signup/job-poster`}
                                onClick={() => setShowDropdown(false)}
                              >
                                Job Poster
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item custom-dropdown-item"
                                to={`/signup/agency`}
                                onClick={() => setShowDropdown(false)}
                              >
                                Agency
                              </Link>
                            </li>
                          </ul>
                        ) : null}
                      </div>

                      {/* Hamburger icon */}
                      <a
                        className="menubar"
                        onClick={() => setShowMobileSideMenu((prev) => !prev)}
                      >
                        <img
                          src="https://creativelayers.net/themes/freeio-html/images/mobile-dark-nav-icon.svg"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="posr">
                  <div className="mobile_menu_close_btn">
                    <span className="far fa-times"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showMobileSideMenu && (
            <nav
              id="menu"
              className="mm-menu mm-menu--offcanvas mm-menu--position-left-front mm-menu--theme-light mm-menu--opened"
              aria-label="Menu"
              aria-modal="true"
              role="dialog"
            >
              <div className="mm-panels">
                <div
                  className={`mm-panel mm-panel--noanimation ${
                    showSignInOption ? "mm-panel--parents" : "mm-panel--opened"
                  }`}
                  id="mm-1"
                >
                  <div className="mm-navbar">
                    <div
                      className="mm-navbar__title"
                      tabIndex="-1"
                      aria-hidden="true"
                    >
                      <span className="">Menu</span>
                      <div
                        className="sidebar-close-icon"
                        style={{
                          backgroundColor: "#d1d4f1",
                          top: "10px",
                          right: "4px",
                        }}
                      >
                        <span
                          className="far fa-times"
                          onClick={() => setShowMobileSideMenu(false)}
                        ></span>
                      </div>
                    </div>
                  </div>
                  <ul className="mm-listview">
                    <li className="mm-listitem" id="mm-2" data-mm-child="mm-3">
                      <a
                        className="mm-btn mm-btn--next mm-listitem__btn mm-listitem__text"
                        aria-label="Open submenu"
                        onClick={() => setShowSignInOption(true)}
                      >
                        SignIn
                      </a>
                    </li>
                    <li className="mm-listitem" id="mm-4" data-mm-child="mm-5">
                      <Link
                        className="mm-btn mm-listitem__btn mm-listitem__text"
                        aria-label="Open submenu"
                        to="/jobs"
                        onClick={() => {
                          setShowMobileSideMenu(false);
                        }}
                      >
                        Find Jobs
                      </Link>
                    </li>
                    <li
                      className="mm-listitem"
                      id="mm-12"
                      data-mm-child="mm-13"
                    >
                      <Link
                        className="mm-btn  mm-listitem__btn mm-listitem__text"
                        aria-label="Open submenu"
                        to="/photographers"
                        onClick={() => {
                          setShowMobileSideMenu(false);
                        }}
                      >
                        Find Photographers
                      </Link>
                    </li>
                    <li
                      className="mm-listitem"
                      id="mm-20"
                      data-mm-child="mm-21"
                    >
                      <Link
                        className="mm-btn mm-listitem__btn mm-listitem__text"
                        aria-label="Open submenu"
                        to="/subscriptions"
                        onClick={() => {
                          setShowMobileSideMenu(false);
                        }}
                      >
                        Subscription Plans
                      </Link>
                    </li>
                  </ul>
                </div>
                <div
                  className={`mm-panel ${
                    showSignInOption ? "mm-panel--opened" : "mm-panel--highest"
                  }`}
                  id="mm-3"
                  data-mm-parent="mm-2"
                >
                  <div className="mm-navbar">
                    <div
                      className="mm-btn mm-btn--prev mm-navbar__btn"
                      aria-label="Close submenu"
                      onClick={() => setShowSignInOption(false)}
                    ></div>
                    <div className="mm-navbar__title">
                      <span className="">SignIn</span>
                    </div>
                    <div
                      className="sidebar-close-icon"
                      style={{
                        backgroundColor: "#d1d4f1",
                        top: "10px",
                        right: "4px",
                      }}
                    >
                      <span
                        className="far fa-times"
                        onClick={() => setShowMobileSideMenu(false)}
                      ></span>
                    </div>
                  </div>
                  <ul className="mm-listview">
                    <li className="mm-listitem">
                      <Link
                        to="/login/photographer"
                        onClick={() => {
                          setShowMobileSideMenu(false);
                          setShowSignInOption(false);
                        }}
                        className="mm-listitem__text"
                      >
                        As Photographer
                      </Link>
                    </li>
                    <li className="mm-listitem">
                      <Link
                        to="/login/job-poster"
                        onClick={() => {
                          setShowMobileSideMenu(false);
                          setShowSignInOption(false);
                        }}
                        className="mm-listitem__text"
                      >
                        As Job poster
                      </Link>
                    </li>
                    <li className="mm-listitem">
                      <Link
                        to="/login/agency"
                        className="mm-listitem__text"
                        onClick={() => {
                          setShowMobileSideMenu(false);
                          setShowSignInOption(false);
                        }}
                      >
                        As Agency
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          )}
        </>
      )}
    </>
  );
};

export default Navbar;
