import React, { useState } from "react";
import MyMessages from "../../features/user/components/MyMessages/MyMessages";
import SideMenu from "../../shared/SideMenu/SideMenu";
import { UseIsDesktopOrLaptop } from "../../utils/Responsive/Responsive";
import BackIcon from "../../utils/Icons/BackIcon";
import { useNavigate } from "react-router-dom";
const MyMessagesPage = () => {
  const isDesktopOrLaptop = UseIsDesktopOrLaptop();
  const navigate = useNavigate();
  const [title, setTitle] = useState("Messages");
  const [showChat, setShowChat] = useState(false);
  const [currentChatId, setCurrentChatId] = useState(null);

  const handleClick = () => {
    setShowChat(false);
    setCurrentChatId(null);
    setTitle("Messages");
    navigate("/messages");
  };

  return (
    <div className="dashboard_content_wrapper" style={{ marginTop: "-35px" }}>
      <div className="dashboard dashboard_wrapper pr30 pr0-xl">
        <SideMenu />
        <div
          className={isDesktopOrLaptop ? "dashboard__main" : ""}
          style={{ marginTop: "35px" }}
        >
          <div
            className="dashboard__content hover-bgc-color"
            style={{ padding: "26px 15px 20px", minHeight: "1000px" }}
          >
            <div className="row pb40">
              {/* <MobileSideBar /> */}
              <div className="col-lg-12 pb10">
                <div
                  className="dashboard_title_area"
                  style={{ marginTop: isDesktopOrLaptop ? "20px" : "" }}
                >
                  {isDesktopOrLaptop ? (
                    <h4>{title}</h4>
                  ) : (
                    <button className="d-flex" onClick={handleClick}>
                      {title !== "Messages" && <BackIcon />}
                      <h4 style={{ margin: "3px" }}>{title}</h4>
                    </button>
                  )}
                </div>
              </div>

              <MyMessages
                setTitle={setTitle}
                setShowChat={setShowChat}
                showChat={showChat}
                setCurrentChatId={setCurrentChatId}
                currentChatId={currentChatId}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyMessagesPage;
