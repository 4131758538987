import React, { useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { useEffect } from "react";
import { myDetail } from "../../../../API/UserApi";
import { selectAccessToken } from "../../../auth/authSlice";
import { v4 as uuidv4 } from "uuid";
import { Status } from "../../../../constants/constant";
import { useSelector } from "react-redux";
import "./ProfileRegistration.css";
import BasicDetails from "./Tab/BasicDetails";
import AddPortfolio from "./Tab/AddPortfolio";
import { logoutUser } from "../../../../common/logOut";

const ProfileRegistration = ({ showModal, setShowModal }) => {
  // const [show, setShow] = useState(false);
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState(null);

  const guid = uuidv4();
  const token = useSelector(selectAccessToken);
  const [status, setStatus] = useState("");
  const [selectedOptions, setSelectedOptions] = useState(
    formData?.photographerDetails?.skills || []
  );
  const fileInputRef = useRef(null);

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  // const handleRemove = (e, valueToRemove) => {
  //   e.preventDefault();
  //   const updatedOptions = selectedOptions.filter(
  //     (value) => value !== valueToRemove
  //   );
  //   setSelectedOptions(updatedOptions);
  // };

  const myDetails = async () => {
    setStatus(Status.PENDING);
    try {
      const data = await myDetail();
      setFormData(data);
      localStorage.setItem("currentUser", JSON.stringify(data));
      setSelectedOptions(data?.photographerDetails?.skills);
      setStatus(Status.IDLE);
    } catch (error) {
      console.error("Error while getting user details", error);
      setStatus(Status.REJECTED);
      // ErrorToaster('Something went wrong.');;;
    }
  };

  useEffect(() => {
    myDetails();
  }, [token]);

  const handleClose = () => {
    setShowModal(false);
    setStep(1);
    logoutUser();
  };

  const handleNext = () => {
    if (step === 1) {
      setStep(2);
    } else {
      handleClose();
    }
  };

  const handleBackClick = () => {
    if (step === 2) {
      setStep(1);
    } else {
      // Perform final submission or processing here
      handleClose();
    }
  };

  return (
    <>
      <Modal
        size="xl"
        show={showModal}
        onHide={handleClose}
        dialogClassName="custom-modal-width" // Apply custom class here
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Profile Registration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {step === 1 && (
            <BasicDetails
              handleNext={handleNext}
              step={step}
              formData={formData}
              setFormData={setFormData}
            />
          )}
          {step === 2 && (
            <AddPortfolio
              formData={formData}
              step={step}
              handleBackClick={handleBackClick}
            />
          )}
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="primary" onClick={handleNext}>
            {step === 1 ? 'Next' : 'Submit'}
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};
export default ProfileRegistration;
