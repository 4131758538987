import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { AdditionalInfoRenderer } from "../../utils/AdditionalInfoRenderer";

const AcceptInviteModal = ({
  showModal,
  onClose,
  handleAccept,
  createNewChat,
  inviteMessage,
  setIsPageReloaded,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async ({ message }) => {
    await handleAccept();
    await createNewChat({ message });
    reset();
    setIsPageReloaded(true);
    onClose();
  };

  return (
    <>
      <Modal show={showModal} onHide={onClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title style={{ textTransform: "capitalize" }}>
            Accept Job Invitation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="mb20">
                {/* Display the most recent message */}
                <label className="form-label fw600 dark-color">
                  Job Poster Message
                </label>
                <div
                  className="message-box p-3"
                  style={{
                    height: "150px",
                    backgroundColor: "#f8f9fa",
                    border: "1px solid #e6dcdc",
                  }}
                >
                  <div className="text-start">
                    <p>
                      {" "}
                      <AdditionalInfoRenderer
                        text={inviteMessage}
                        maxLength={500}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              {/* Input form for new message */}
              <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <div className="mb20">
                  <label
                    htmlFor="message"
                    className="form-label fw600 dark-color"
                  >
                    Message
                    <span className="required-field">*</span>
                  </label>
                  <textarea
                    className="pt15 form-control"
                    {...register("message", {
                      required: "Message is required",
                    })}
                    rows="4"
                    placeholder="Type your message here..."
                  ></textarea>
                  {errors?.message && (
                    <p className="text-danger fw500">
                      {errors?.message?.message}
                    </p>
                  )}
                </div>
                <div className="text-end">
                  <Button className="ud-btn btn-thm" type="submit">
                    Send And Accept<i className="fal fa-arrow-right-long"></i>
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AcceptInviteModal;
