function HamburgerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 216 216"
      role="img"
      width="40"
      height="40"
      // style={{ width: "70%", marginLeft: "38px" }}
    >
      <path d="M41.26,57.37l133.22-.08c7.86.88,7.83,13.27,0,14.25H41.86c-7.58-.85-7.91-12.59-.6-14.17Z" />
      <path d="M41.26,100.79l133.22-.08c7.86.88,7.83,13.27,0,14.25H41.86c-7.58-.85-7.91-12.59-.6-14.17Z" />
      <path d="M41.26,144.2l133.22-.08c7.86.88,7.83,13.27,0,14.25H41.86c-7.58-.85-7.91-12.59-.6-14.17Z" />
    </svg>
  );
}

export default HamburgerIcon;
