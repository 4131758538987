import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import {
  ErrorToaster,
  SuccessToaster,
} from "../../../../shared/Toasters/toaster";
import { upload } from "../../../user/userApi";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  addPortfolio,
  deleteCategory,
  getPortfolios,
} from "../../../../API/UserApi";
import Loader from "../../../../utils/Loader";
import { getUserInfo } from "../../../../common/getUserInfo";
import { Status } from "../../../../constants/constant";
import { EmptyComponent } from "../../../../shared/Empty/Empty";
import imageCompression from "browser-image-compression";
import { CategoryWarningModal } from "../../../../shared/Modal/CategoryWarningModal";
import { useNavigate } from "react-router-dom";
import {
  getCategoryWarningMessage,
  getDeleteConfirmation,
  hasMinFiveImages,
  hasMinFiveImagesInCategory,
  LIMIT_TITLES,
} from "../../../../utils";
import Swal from "sweetalert2";

const AddPortfolio = () => {
  const [showModal, setShowModal] = useState(false);
  const [newCategory, setNewCategory] = useState(null);
  const [myPortfolios, setMyPortfolios] = useState([]);
  const [status, setStatus] = useState("");
  const [error, setError] = useState("");
  const [isImgUploading, setIsImgUploading] = useState(false);
  const [isCategoryWarningOpen, setIsCategoryWarningOpen] = useState({
    status: false,
    type: "",
  });
  const [selectedPortfolio, setSelectedPortfolio] = useState(null);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const userInfo = getUserInfo();
  const navigate = useNavigate();

  const { usePlanDetails, photographerDetails, profileStatus } = userInfo || {};
  const { skills } = photographerDetails || {};
  const { pictureLimit, categoryLimit } = usePlanDetails || {};

  const handleChange = (e) => {
    setError("");
    const selectedValue = e.target.value;
    setNewCategory({
      category: selectedValue,
      imageUrls: [],
    });
  };

  const getMyPortfolios = async () => {
    setStatus(Status.PENDING);
    try {
      const res = await getPortfolios();
      setMyPortfolios(res || []);
      setStatus(Status.IDLE);
    } catch (error) {
      setStatus(Status.REJECTED);
      ErrorToaster(error?.message);
      setError(error?.message);
    }
  };

  const deletePortfolio = async (category) => {
    const isConfirmed = await getDeleteConfirmation({
      description: `Are you sure want to delete the ${category} category portfolio`,
    });
    if (!isConfirmed) return;
    const swalLoading = Swal.fire({
      title: "Please Wait",
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading(),
    });
    try {
      const { message } = await deleteCategory({ category });
      SuccessToaster(message);
      getMyPortfolios({});
    } catch (error) {
      ErrorToaster(error?.message);
      setError(error?.message);
    } finally {
      swalLoading.close();
    }
  };

  const addMyPortfolio = async (newCategory) => {
    setStatus(Status.PENDING);
    try {
      const res = await addPortfolio(newCategory);
      SuccessToaster(res);
      await getMyPortfolios({});
      setStatus(Status.IDLE);
    } catch (error) {
      setStatus(Status.REJECTED);
      ErrorToaster(error?.message);
      setError(error?.message);
    }
  };
  const handleFileChange = async (e) => {
    setIsImgUploading(true);
    const selectedCategory = e.target.dataset.category;
    const files = e.target.files;
    // const uploadResponses = [];
    let mediaArrays = myPortfolios
      .filter((item) => item.category === selectedCategory)
      .flatMap((item) => item.media);

    if (files.length + mediaArrays.length > pictureLimit) {
      setIsImgUploading(false);
      ErrorToaster(`Maximum ${pictureLimit} files are allowed.`);
      return;
    }
    try {
      const formDataArray = await Promise.all(
        Array.from(files).map(async (file) => {
          const formData = new FormData();
          if (file.size > 5 * 1024 * 1024) {
            const options = {
              maxSizeMB: 5,
              maxWidthOrHeight: 1920,
              useWebWorker: true,
            };
            const compressedBlob = await imageCompression(file, options);
            // Convert the compressed Blob back to a File
            const compressedFile = new File([compressedBlob], file.name, {
              type: file.type, // Preserve the original file type
              lastModified: Date.now(), // Set last modified date to now or adjust as needed
            });
            if (compressedFile) {
              formData.append("file", compressedFile); // Append the file
              formData.append("guid", uuidv4()); // Append the guid
              formData.append("mediaType", "portfolio"); // Append the mediaType
              return formData;
            }
          } else {
            formData.append("file", file); // Append the file
            formData.append("guid", uuidv4()); // Append the guid
            formData.append("mediaType", "portfolio"); // Append the mediaType
            return formData;
          }
        })
      );
      const responses = await Promise.all(
        formDataArray.map((formData) => upload(formData))
      );
      if (responses.every((res) => res?.status === 200 && res?.data)) {
        setIsImgUploading(false);
        SuccessToaster("All files uploaded successfully.");
        const mediaArrays = myPortfolios
          .filter((item) => item.category === selectedCategory)
          .flatMap((item) => item.media);

        const uploadedMedia = responses.map((res) => res.data.high);
        const newMediaArray = [...mediaArrays, ...uploadedMedia];

        const newPortfolioData = {
          category: selectedCategory,
          media: newMediaArray,
        };

        await addMyPortfolio(newPortfolioData);
      } else {
        setIsImgUploading(false);
        ErrorToaster("Failed to upload one or more files. Please try again.");
      }
    } catch (err) {
      setIsImgUploading(false);
      ErrorToaster(
        err?.response?.data?.message ||
          "An error occurred while uploading files."
      );
    }
  };

  const handleSave = () => {
    if (!newCategory) setError("Category is required");
    addMyPortfolio(newCategory);
    handleClose();
  };

  useEffect(() => {
    getMyPortfolios({});
  }, []);

  const deletePortfoliosImg = async () => {
    const updatedPortfolios = myPortfolios.find(
      (item) => item?._id === selectedPortfolio?.portfolioId
    );
    updatedPortfolios?.media?.splice(selectedPortfolio?.selectedImageIndex, 1);
    const newPortfolioData = {
      category: updatedPortfolios?.category,
      media: updatedPortfolios?.media,
    };
    if (newPortfolioData) {
      await addMyPortfolio(newPortfolioData);
    }
  };

  const handleDeleteClick = async (portfolioIndex, imageIndex) => {
    setSelectedPortfolio({
      portfolioId: portfolioIndex,
      selectedImageIndex: imageIndex,
    });
    const isCategoryWithMinImages = hasMinFiveImagesInCategory(
      portfolioIndex,
      myPortfolios
    );
    if (isCategoryWithMinImages) {
      setIsCategoryWarningOpen({ status: true, type: "minImgLimit" });
    } else {
      await deletePortfoliosImg();
    }
  };

  const handleUpgradePlanClick = () => navigate("/subscriptions");

  const handleDeletePortfolio = async (portfolioCategory) => {
    const result = hasMinFiveImages(myPortfolios, portfolioCategory);
    if (myPortfolios.length > 1 && result) {
      await deletePortfolio(portfolioCategory);
    } else {
      setIsCategoryWarningOpen({
        status: true,
        type: "minImgLimitForPortfolio",
      });
    }
  };

  return (
    <>
      {isCategoryWarningOpen && (
        <CategoryWarningModal
          title={
            LIMIT_TITLES[isCategoryWarningOpen.type] || LIMIT_TITLES.default
          }
          show={isCategoryWarningOpen.status}
          onHide={() => setIsCategoryWarningOpen(false)}
          message={getCategoryWarningMessage(isCategoryWarningOpen.type)}
          secondaryButtonText={
            ["maxImgLimit", "maxCategoryLimit"].includes(
              isCategoryWarningOpen.type
            )
              ? "Not Now"
              : "Close"
          }
          primaryButtonText={
            ["maxImgLimit", "maxCategoryLimit"].includes(
              isCategoryWarningOpen.type
            ) && "Upgrade Plan"
          }
          handlePrimaryButtonClick={
            ["maxImgLimit", "maxCategoryLimit"].includes(
              isCategoryWarningOpen.type
            ) && handleUpgradePlanClick
          }
        />
      )}
      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Portfolio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-sm-10">
            <div className="mb20">
              <div className="form-style1">
                <label className="heading-color ff-heading fw500 mb10">
                  Category
                </label>
                <div className="bootselect-multiselect">
                  <select className="form-control" onChange={handleChange}>
                    <option value="" selected disabled>
                      {" "}
                      Select Your Category
                    </option>
                    {skills
                      ?.filter(
                        (skill) =>
                          !myPortfolios.some(
                            (portfolio) => portfolio.category === skill
                          )
                      )
                      .map((category, index) => (
                        <option key={index} value={category}>
                          {category}
                        </option>
                      ))}
                  </select>
                </div>
                {error && <p className="text-danger">{error}</p>}
                <div className="mt10">
                  <strong className="fz18">Note: </strong>{" "}
                  <span className="fz16">
                    If you do not find the name of the category you want to add
                    to your portfolio, please add the category name to your
                    profile details first.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button className="ud-btn btn-dark" onClick={handleClose}>
            Close
          </Button> */}
          <Button
            className="ud-btn btn-dark"
            disabled={profileStatus === Status.PENDING_FOR_REVIEW}
            onClick={handleSave}
          >
            Add Portfolio
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <div className="dashboard__main" style={{ marginTop: '5px' }}> */}
      <div className="dashboard__content hover-bgc-color">
        <div className="row pb40" style={{ marginTop: "-35px" }}>
          <div className="col-lg-9">
            {profileStatus === Status.PENDING_FOR_REVIEW ? (
              <p style={{ fontSize: "16px" }}>
                Note: Your profile is currently under review. You may make
                changes to your profile once we have completed the review
                process and taken appropriate action.
              </p>
            ) : null}
          </div>
          <div className="col-lg-3">
            <div className="text-lg-end">
              <button
                className="ud-btn btn-dark"
                disabled={profileStatus === Status.PENDING_FOR_REVIEW}
                onClick={
                  myPortfolios?.length === categoryLimit
                    ? () =>
                        setIsCategoryWarningOpen({
                          status: true,
                          type: "maxCategoryLimit",
                        })
                    : handleShow
                }
              >
                Add Portfolio<i className="fal fa-arrow-right-long"></i>
              </button>
            </div>
          </div>
        </div>
        {status === Status.PENDING ? (
          <Loader />
        ) : (
          <>
            {myPortfolios?.length > 0 ? (
              <div className="row mt5" style={{ margin: "-15px" }}>
                {myPortfolios
                  // ?.slice(0, 3)
                  .reverse()
                  .map((portfolio, portfolioIndex) => (
                    <div
                      className="ps-widget bgc-white bdrs0 p10 mb30 overflow-hidden position-relative"
                      style={{ borderRadius: "5px" }}
                      key={portfolio?._id}
                    >
                      <div className="bdrb1 pb15 mb30 d-flex justify-content-between align-items-center">
                        <h5 className="list-title">{portfolio?.category}</h5>
                        <div>
                          {profileStatus === Status.COMPLETED && (
                            <span
                              className="text-decoration-underline fz14 fw500 text-red ff-heading cursor-pointer"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handleDeletePortfolio(portfolio?.category)
                              }
                            >
                              Delete Portfolio
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="d-flex flex-wrap flex-row align-content-stretch justify-content-start align-items-start mb40">
                          {portfolio?.media.map((imageUrl, imageIndex) => (
                            <div
                              key={imageIndex}
                              className="gallery-item me-3 mb-3 bdrs4 overflow-hidden position-relative"
                              style={{ width: "18%" }}
                            >
                              <div className="image-container">
                                <img
                                  className="gallery-image"
                                  src={imageUrl}
                                  alt={`Gallery portfolio ${imageIndex + 1}`}
                                />
                              </div>
                              {profileStatus === Status.COMPLETED && (
                                <div className="del-edit">
                                  <div className="d-flex justify-content-center">
                                    <button
                                      className="icon"
                                      onClick={() =>
                                        handleDeleteClick(
                                          portfolio?._id,
                                          imageIndex
                                        )
                                      }
                                    >
                                      <span className="flaticon-delete"></span>
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                          {profileStatus === Status.COMPLETED &&
                          portfolio?.media?.length < pictureLimit ? (
                            <div className="gallery-item me-3 mb-3 bdrs4 overflow-hidden position-relative">
                              <label
                                htmlFor={`fileInput-${portfolioIndex}`}
                                className="w-60"
                                // style={{ width: "255px" }}
                              >
                                <input
                                  type="file"
                                  className="d-none"
                                  id={`fileInput-${portfolioIndex}`}
                                  onChange={handleFileChange}
                                  data-category={portfolio?.category}
                                  multiple
                                  accept=".png, .jpg, .jpeg, .JPG"
                                  // accept=".jpg, .jpeg, .png" // Specify accepted file types
                                  max="2"
                                />
                                {isImgUploading ? (
                                  <div style={{ margin: "60px" }}>
                                    <Loader isUploading={true} />
                                  </div>
                                ) : (
                                  <img
                                    className="w-60 rounded-2"
                                    src="https://creativelayers.net/themes/freeio-html/images/gallery/g-1.png"
                                    alt="UploadImage"
                                    style={{ width: "100%" }}
                                  />
                                )}
                              </label>
                            </div>
                          ) : (
                            <div
                              className="gallery-item me-3 mb-3 bdrs4 overflow-hidden position-relative"
                              style={{ width: "50%" }}
                              onClick={() =>
                                setIsCategoryWarningOpen({
                                  status: true,
                                  type: "maxImgLimit",
                                })
                              }
                            >
                              <img
                                className="w-60 rounded-2"
                                src="https://creativelayers.net/themes/freeio-html/images/gallery/g-1.png"
                                alt="UploadImage"
                                style={{ width: "36%" }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <hr />
                    </div>
                  ))}
              </div>
            ) : (
              <div className="ps-widget bgc-white bdrs0 p10 mb10 overflow-hidden position-relative">
                <div
                  style={{
                    marginTop: "150px",
                  }}
                >
                  <EmptyComponent
                    description="No Data."
                    // type={true}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {/* </div> */}
    </>
  );
};

export default AddPortfolio;
