import React, { useRef } from "react";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";

const libraries = ["places"];

function MapComponent({
  name,
  register,
  setValue,
  placeholder,
  setFilterLocation,
  required,
}) {
  const inputRef = useRef(null);

  const handlePlaceChanged = () => {
    const places = inputRef.current.getPlaces();
    if (places.length > 0) {
      const place = places[0];
      if (name === "filterLocation") {
        setFilterLocation(place?.formatted_address);
      } else {
        setValue(name, place?.formatted_address);
      }
    }
  };

  return (
    <StandaloneSearchBox
      onLoad={(ref) => (inputRef.current = ref)}
      onPlacesChanged={handlePlaceChanged}
    >
      <input
        type="text"
        placeholder={placeholder}
        className="form-control"
        {...register(name, {
          ...(required && { required: "Location is required" }),
        })}
      />
    </StandaloneSearchBox>
  );
}

export default React.memo(MapComponent);
