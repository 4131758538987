import React from "react";
import { Form, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";

// import { ERRORS } from "@framework/utils/error-message";
// import { normalizeFieldName } from "@framework/utils/validationHandler";

const InputField = ({
  label,
  name,
  error,
  register,
  helperText,
  iconPosition,
  icon,
  required = false,
  type,
  labelIcon,
  tooltipContent,
  className,
  validation = {},
  maxLength,
  id,
  iconClass = "",
  labelClass = "",
  groupClass = "",
  helperTextProps = {},
  errorMessage,
  ...rest
}) => {
  return (
    <Form.Group
      className={`mb-3 ${rest.disabled ? "disabled" : ""} ${groupClass}`}
    >
      {label && (
        <Form.Label className={labelClass}>
          {label}{" "}
          {required && (
            <span className="required-filed">
              <span className="required-field">*</span>
            </span>
          )}
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{tooltipContent}</Tooltip>}
          >
            <span className="cursor-pointer ms-1">{labelIcon}</span>
          </OverlayTrigger>
        </Form.Label>
      )}
      <InputGroup>
        {iconPosition === "before" && icon && (
          <InputGroup.Text className={iconClass} id={id}>
            {icon}
          </InputGroup.Text>
        )}
        <Form.Control
          type={type}
          {...register(name, {
            required: required && errorMessage,
            ...validation,
          })}
          {...(type === "number" && { step: "any" })}
          isInvalid={error}
          className={className}
          maxLength={maxLength}
          {...rest}
        />
        {iconPosition === "after" && icon && (
          <InputGroup.Text className={`icon-right ${iconClass}`}>
            {icon}
          </InputGroup.Text>
        )}
      </InputGroup>
      {error && <Form.Text className="text-danger">{error}</Form.Text>}
      {helperText && (
        // logic can be improved
        <Form.Text className={helperTextProps?.error ? "text-danger" : ""}>
          <span dangerouslySetInnerHTML={{ __html: helperText }} />
        </Form.Text>
      )}
    </Form.Group>
  );
};

export default InputField;
