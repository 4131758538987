import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { RangeSlider } from "rsuite";
import "rsuite/dist/rsuite.css";

const Price = ({ setFilterPrice, filterPrice }) => {
  const [searchParams] = useSearchParams();
  console.log("filterPrice", filterPrice);

  // Extract the 'category' parameter from the search parameters
  const selectedJobRateMax = searchParams.get("jobRateMax");
  const selectedJobRateMin = searchParams.get("jobRateMin");

  const [show, setShow] = useState(true);

  useEffect(() => {
    setFilterPrice({
      min: filterPrice?.min ?? selectedJobRateMin,
      max: filterPrice?.max ?? selectedJobRateMax,
    });
  }, [
    setFilterPrice,
    selectedJobRateMin,
    selectedJobRateMax,
    filterPrice?.min,
    filterPrice?.max,
  ]);

  const handleRanges = (value) => {
    setFilterPrice({ min: value[0], max: value[1] });
  };

  const handleMinRangeChange = (e) => {
    const value = parseInt(e.target.value);

    setFilterPrice({ ...filterPrice, min: value });
    if (value > filterPrice?.max) {
      setFilterPrice({ ...filterPrice, max: value });
    }
  };

  const handleMaxRangeChange = (e) => {
    const value = parseInt(e.target.value);
    setFilterPrice({ ...filterPrice, max: value });
    if (value < filterPrice?.min) {
      setFilterPrice({ ...filterPrice, min: value });
    }
  };

  return (
    <div className="card mb20 pb10 mt-0">
      <div className={`${show ? "active" : ""}`} id="heading0">
        <h4>
          <button
            className="btn btn-link ps-0"
            type="button"
            onClick={() => setShow((prev) => !prev)}
          >
            Price
          </button>
        </h4>
      </div>
      <div
        id="collapse0"
        className={`collapse ${show ? "show" : ""}`}
        aria-labelledby="heading0"
        data-parent="#accordionExample"
        style={{
          width: "16rem",
          padding: "20px",
          marginTop: "-30px",
        }}
      >
        {/* <RangeSlider
          min={1}
          max={100000}
          defaultValue={[1, 100000]}
          onChange={handleRanges}
          className="custom-slider"
        /> */}
        <br />
        <div className="text-center">
          <div
            className="d-flex justify-content-around"
            style={{
              display: "inline-block",
              marginRight: "20px",
              alignItems: "center",
            }}
          >
            <label
              htmlFor="min-input"
              style={{ display: "block", fontWeight: "bold" }}
            >
              Min
            </label>
            <input
              id="min-input"
              type="number"
              className="amount"
              value={filterPrice?.min}
              style={{ marginLeft: "80px" }}
              onFocus={(e) => (e.target.style.border = "2px solid #007bff")}
              onBlur={(e) => (e.target.style.border = "1px solid #ccc")}
              onChange={handleMinRangeChange}
            />
          </div>
          <div
            className="d-flex justify-content-around"
            style={{ marginTop: "15px", alignItems: "center" }}
          >
            <label
              htmlFor="max-input"
              style={{ display: "block", fontWeight: "bold" }}
            >
              Max
            </label>
            <input
              id="max-input"
              type="number"
              className="amount2"
              value={filterPrice?.max}
              style={{ marginLeft: " 80px", alignItems: "center" }}
              onFocus={(e) => (e.target.style.border = "2px solid #007bff")}
              onBlur={(e) => (e.target.style.border = "1px solid #ccc")}
              onChange={handleMaxRangeChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Price;
