function BellIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      viewBox="0 0 216 216"
      width="24"
      height="24"
      role="img"
      style={{ width: "40px", height: "40px" }}
    >
      <defs>
        <style>{`.st0 { fill: #fff; }`}</style>
      </defs>
      <path d="M113.2 26l8.4 1.1c34.2 5.6 61.9 33.1 67.6 67.3l1.1 7.8v12.5c-.5 1.7-.5 3.7-.7 5.5-5.3 36-34.3 64.9-70.4 69.6-2.1.3-4.2.2-6.2.7h-10c-1.9-.5-4-.4-5.9-.7-36.3-4.8-65.7-34.2-70.5-70.5-.3-1.9-.2-4-.7-5.9.2-3.4-.2-6.9 0-10.3C28.2 62.1 62.1 28.2 102.9 26h10.3zm34.6 115.7v-5c0-.7-9.4-8.5-9.9-10.4-1.1-16 3.7-34.5-7.9-47.6-3.8-4.3-8.9-7.1-14.5-8.6-.3-3.1.3-5.8-1.9-8.3-2.9-3.3-8.6-3.3-11.3.2-2.1 2.7-1.5 5-1.8 8.2-6.4 1.7-12.1 5.3-16 10.6-9.8 13.3-5.2 30.3-6.4 45.7-.5 1.8-9.9 9.6-9.9 10.3v5h79.6zm-29.9 4.8h-19.9c.3 13.4 19.6 13.4 19.9 0z" />
      <path
        className="st0"
        d="M147.8 141.7h-79.6v-5c0-.7 9.4-8.5 9.9-10.3 1.1-15.3-3.4-32.4 6.4-45.7 3.9-5.3 9.7-8.9 16-10.6.3-3.2-.3-5.5 1.8-8.2 2.8-3.5 8.4-3.5 11.3-.2 2.2 2.6 1.7 5.2 1.9 8.3 5.6 1.5 10.6 4.3 14.5 8.6 11.7 13.2 6.8 31.6 7.9 47.6.5 1.8 9.9 9.6 9.9 10.4v5z"
      />
      <path className="st0" d="M118 146.5c-.3 13.4-19.6 13.4-19.9 0h19.9z" />
    </svg>
  );
}

export default BellIcon;
