import React, { useState, Suspense } from "react";
import Loader from "../../../utils/Loader";
import { Status } from "../../../constants/constant";

const CreatedJobsTab = React.lazy(() => import("./MyJobTabs/CreatedJobsTab"));
const CompletedJobsTab = React.lazy(() =>
  import("./MyJobTabs/CompletedJobsTab")
);

const MyJobs = () => {
  const [activeTab, setActiveTab] = useState(Status.ACTIVE);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="ps-widget bgc-white bdrs4 p30 mb30 overflow-hidden position-relative mt10">
      <div className="navtab-style1">
        <nav>
          <div className="nav nav-tabs mb30" role="tablist">
            {/* <button
              className={`nav-link ${
                activeTab === "open" ? "active fw500" : "fw500"
              }`}
              type="button"
              onClick={() => handleTabClick(Status.OPEN)}
            >
              Open Jobs
            </button> */}
            <button
              className={`nav-link ${
                activeTab === Status.ACTIVE ? "active fw500 ps-0" : "fw500"
              }`}
              type="button"
              onClick={() => handleTabClick(Status.ACTIVE)}
            >
              Active Jobs
            </button>
            <button
              className={`nav-link ${
                activeTab === Status.COMPLETED ? "active fw500" : "fw500"
              }`}
              type="button"
              onClick={() => handleTabClick(Status.COMPLETED)}
            >
              Completed Jobs
            </button>
            {/* Add more buttons for other tabs if needed */}
          </div>
        </nav>
        <div className="tab-content">
          {/* {activeTab === Status.OPEN && (
            <Suspense fallback={<Loader />}>
              <OpenJobsTab />
            </Suspense>
          )} */}
          {activeTab === Status.ACTIVE && (
            <Suspense fallback={<Loader />}>
              <CreatedJobsTab />
            </Suspense>
          )}
          {activeTab === Status.COMPLETED && (
            <Suspense fallback={<Loader />}>
              <CompletedJobsTab />
            </Suspense>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyJobs;
