import React from "react";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import "./index.css";
import "./assets/css/ud-custom-spacing.css";
import "./sass/style.scss";
import "./assets/css/flaticon.css";

import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import LoginPage from "./pages/login/LoginPage";
import SignupPage from "./pages/signup/SignupPage";
import MyProfilePage from "./pages/myProfile/MyProfilePage";
import JobsListingPage from "./pages/JobsListing/JobsListingPage";
import JobDetailsPage from "./pages/JobsListing/JobDetailsPage";
import PhotographersListingPage from "./pages/PhotographersListingPage/PhotographersListingPage";
import PhotographerDetailsPage from "./pages/PhotographersListingPage/PhotographerDetailsPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Protected from "./features/auth/components/Protected";
import ForgetPasswordPage from "./pages/forgetPassword/ForgetPasswordPage";
import ResetPasswordPage from "./pages/resetPassword/ResetPasswordPage";
import PostJobPage from "./pages/JobsListing/PostJobPage";
import PageNotFoundPage from "./pages/404/PageNotFoundPage";
import Home from "./components/Home/Home";
import RedirectIfLoggedIn from "./shared/RedirectIfLoggedIn/RedirectIfLoogedIn";
import AddPortfolioPage from "./pages/PortfolioPage/AddPortfolioPage";
import MyJobsPage from "./pages/JobsListing/MyJobsPage";
import MyMessagesPage from "./pages/myProfile/MyMessages";
import PhotographerJobsPage from "./pages/Photographer/PhotographerJobsPage";
import SubscriptionPlansPage from "./pages/SubscriptionPlansPage/SubscriptionPlansPage";
import MyPlanPage from "./pages/Photographer/MyPlanPage";
import DashboardPage from "./pages/admin/Dashboard/Dashboard";
import ManageJobsPage from "./pages/admin/ManageJobsPage/ManageJobsPage";
import ManageJobPostersPage from "./pages/admin/ManageJobPostersPage/ManagerJobPostersPage";
import ManagePhotographersPage from "./pages/admin/ManagePhotographersPage/ManagerPhotographersPage";
import ManageReportsPage from "./pages/admin/ManageReportsPage/ManageReportsPage";
import ManageTransactionsPage from "./pages/admin/ManageTransactionsPage/ManageTransactionsPage";
import JobPosterDetailsPage from "./pages/admin/ManageJobPostersPage/JobPosterDetailsPage";
import FaqPage from "./pages/Faq/FaqPage";
import TermAndConditionsPage from "./pages/TermAndConditions/TermAndConditions";
import PrivacyPolicyPage from "./pages/PrivacyPolicy/PrivacyPolicyPage";
import ScrollToTop from "./utils/ScrollToTop";
import ManageInsuranceDocPage from "./pages/admin/ManageInsuranceDocPage/ManageReportsPage";
import AdminProtectedRoute from "./utils/PrivateRoutes/AdminProtected";
import ContactUsPage from "./pages/ContactUsPage/ContactUsPage";
import AboutUsPage from "./pages/AboutUsPage/AboutUsPage";
import ManageQueriesPage from "./pages/admin/ManageQueriesPage/ManageQueriesPage";
import PhotographerPrivateRoute from "./shared/PrivateRoute/PhotographerPrivateRoute";
import JobPosterPrivateRoute from "./utils/PrivateRoutes/JobPosterPrivateRoute";
import JobInvitesPage from "./pages/Photographer/JobInvitePage";
import EditJobPage from "./pages/JobsListing/EditJobPage";

const Layout = () => {
  // useAuthCheck();
  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
    </>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <ScrollToTop />
        <Layout />
      </>
    ),
    children: [
      {
        path: "/",
        element: (
          <RedirectIfLoggedIn>
            <Home />
          </RedirectIfLoggedIn>
        ),
      },
      {
        path: "/login/:userType",
        element: <LoginPage />,
      },
      // {
      //   path: '/admin',
      //   element: <SignupPage />,
      // },
      {
        path: "/signup/:userType",
        element: (
          // <RedirectIfLoggedIn>
          <SignupPage />
          // </RedirectIfLoggedIn>
        ),
      },
      {
        path: "/profile",
        element: (
          // <Protected>
          <MyProfilePage />
          // </Protected>
        ),
      },
      {
        path: "/change-password",
        element: (
          <Protected>
            <MyProfilePage />
          </Protected>
        ),
      },
      {
        path: "/portfolios",
        element: (
          <PhotographerPrivateRoute>
            <AddPortfolioPage />
          </PhotographerPrivateRoute>
        ),
      },
      {
        path: "/jobs",
        element: <JobsListingPage />,
      },
      {
        path: "/jobs/:id",
        element: (
          // <Protected>
          <JobDetailsPage />
          // </Protected>
        ),
      },
      {
        path: "/photographers",
        element: <PhotographersListingPage />,
      },
      {
        path: "/photographers/:id",
        element: <PhotographerDetailsPage />,
      },
      {
        path: "/forget-password",
        element: (
          <RedirectIfLoggedIn>
            <ForgetPasswordPage />
          </RedirectIfLoggedIn>
        ),
      },
      {
        path: "/execute-forget-password",
        element: (
          <RedirectIfLoggedIn>
            <ResetPasswordPage />
          </RedirectIfLoggedIn>
        ),
      },
      {
        path: "/create-job",
        element: (
          <JobPosterPrivateRoute>
            <PostJobPage />,
          </JobPosterPrivateRoute>
        ),
      },
      {
        path: "/my-jobs/:id/edit",
        element: (
          <JobPosterPrivateRoute>
            <EditJobPage />,
          </JobPosterPrivateRoute>
        ),
      },
      {
        path: "/my-jobs",
        element: (
          <JobPosterPrivateRoute>
            <MyJobsPage />
          </JobPosterPrivateRoute>
        ),
      },
      {
        path: "/my-jobs/:id",
        element: (
          <Protected>
            <JobDetailsPage />
          </Protected>
        ),
      },
      {
        path: "/photographer-jobs",
        element: (
          <PhotographerPrivateRoute>
            <PhotographerJobsPage />
          </PhotographerPrivateRoute>
        ),
      },
      {
        path: "/messages",
        element: (
          <Protected>
            <MyMessagesPage />
          </Protected>
        ),
      },
      // {
      //   path: '/messages/:chatId',
      //   element: <ChatBoxPage />,
      // },
      {
        path: "/subscriptions",
        element: (
          // <RedirectIfLoggedIn>
          <SubscriptionPlansPage />
          // </RedirectIfLoggedIn>
        ),
      },
      {
        path: "/my-plan",
        element: (
          <Protected>
            <MyPlanPage />
          </Protected>
        ),
      },
      {
        path: "/job-invites",
        element: (
          <Protected>
            <JobInvitesPage />
          </Protected>
        ),
      },
      {
        path: "/dashboard",
        element: <DashboardPage />,
      },
      {
        path: "/manage-photographers",
        element: (
          <AdminProtectedRoute>
            <ManagePhotographersPage />
          </AdminProtectedRoute>
        ),
      },
      {
        path: "/manage-photographers/:id",
        element: <PhotographerDetailsPage />,
      },
      {
        path: "/manage-job-posters",
        element: (
          <AdminProtectedRoute>
            <ManageJobPostersPage />
          </AdminProtectedRoute>
        ),
      },
      {
        path: "/manage-job-posters/:id",
        element: <JobPosterDetailsPage />,
      },
      {
        path: "/manage-jobs",
        element: (
          <AdminProtectedRoute>
            <ManageJobsPage />
          </AdminProtectedRoute>
        ),
      },
      {
        path: "/manage-jobs/:id",
        element: <JobDetailsPage />,
      },
      {
        path: "/manage-reports",
        element: (
          <AdminProtectedRoute>
            <ManageReportsPage />
          </AdminProtectedRoute>
        ),
      },
      {
        path: "/manage-queries",
        element: (
          <AdminProtectedRoute>
            <ManageQueriesPage />
          </AdminProtectedRoute>
        ),
      },
      {
        path: "/transactions",
        element: (
          <AdminProtectedRoute>
            <ManageTransactionsPage />
          </AdminProtectedRoute>
        ),
      },
      {
        path: "/manage-insurances",
        element: (
          <AdminProtectedRoute>
            <ManageInsuranceDocPage />
          </AdminProtectedRoute>
        ),
      },
      {
        path: "/faqs",
        element: <FaqPage />,
      },
      {
        path: "/terms-and-conditions",
        element: <TermAndConditionsPage />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicyPage />,
      },
      {
        path: "/contact",
        element: <ContactUsPage />,
      },
      {
        path: "/about",
        element: <AboutUsPage />,
      },
      {
        path: "*",
        element: <PageNotFoundPage />,
      },
    ],
  },
]);

function App() {
  return (
    <div>
      <RouterProvider router={router} forceRefresh={true} />
      <ToastContainer />
    </div>
  );
}

export default App;
