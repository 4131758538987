import React from "react";
import AddPortfolio from "../../features/photographer/components/Portfolio/AddPortfolio";
import SideMenu from "../../shared/SideMenu/SideMenu";
import { UseIsDesktopOrLaptop } from "../../utils/Responsive/Responsive";
import MobileSideBar from "../../shared/MobileSideBar/MobileSideBar";

const AddPortfolioPage = () => {
  const isDesktopOrLaptop = UseIsDesktopOrLaptop();
  return (
    <>
      {/* <div className="dashboard_content_wrapper" style={{ marginTop: '-35px' }}>
        <div className="dashboard dashboard_wrapper pr30 pr0-xl">
          <SideMenu />
          <AddPortfolio />
        </div>
      </div> */}
      <div className="dashboard_content_wrapper" style={{ marginTop: "-35px" }}>
        <div className="dashboard dashboard_wrapper pr30 pr0-xl">
          <SideMenu />
          <div
            className={isDesktopOrLaptop ? "dashboard__main" : ""}
            style={{ marginTop: "50px" }}
          >
            <div
              className="dashboard__content hover-bgc-color"
              style={{ padding: "26px 24px 20px" }}
            >
              <div className="row pb40">
                <MobileSideBar />
                <AddPortfolio />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPortfolioPage;
