import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const JobType = ({ setFilterRateType }) => {
  const [searchParams] = useSearchParams();
  // Extract the 'category' parameter from the search parameters
  const selectedJobType = searchParams.get("jobType");
  const [show, setShow] = useState(true);
  const [selectedType, setSelectedType] = useState(selectedJobType);

  useEffect(() => {
    setSelectedType(selectedJobType);
  }, [selectedJobType]);

  const handleTypeSelect = (type) => {
    if (selectedType === type) {
      setSelectedType(null);
      setFilterRateType(null); // Clear filter if the same type is clicked again
    } else {
      setSelectedType(type);
      setFilterRateType(type);
    }
  };

  return (
    <div className="card mb20 pb10 pr30 ml10">
      <div className={`${show ? "active" : ""}`} id="heading01">
        <h4>
          <button
            className="btn btn-link ps-0"
            type="button"
            onClick={() => setShow((prev) => !prev)}
            style={{ marginLeft: "-10px" }}
          >
            Budget Type
          </button>
        </h4>
      </div>
      <div
        id="collapse01"
        className={`collapse ${show ? "show" : ""}`}
        aria-labelledby="heading01"
        data-parent="#accordionExample"
      >
        <div className="card-body card-body px-0 pt-0">
          <div className="switch-style1">
            <div className="form-check form-switch mb20">
              <input
                className="form-check-input"
                type="checkbox"
                id="fixedType"
                checked={selectedType === "fixed"}
                onChange={() => handleTypeSelect("fixed")}
              />
              <label className="form-check-label" htmlFor="fixedType">
                Fixed
              </label>
            </div>
          </div>
          <div className="switch-style1">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id="hourlyType"
                checked={selectedType === "hourly"}
                onChange={() => handleTypeSelect("hourly")}
              />
              <label className="form-check-label" htmlFor="hourlyType">
                Hourly
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobType;
