import React, { useEffect, useState } from "react";
import { getAllChat, getChatCount } from "../../../../API/ChatApi";
import { Status } from "../../../../constants/constant";
import Loader from "../../../../utils/Loader";
import { ErrorToaster } from "../../../../shared/Toasters/toaster";
import { getUserInfo } from "../../../../common/getUserInfo";
import ChatBox from "./ChatBox";
import { useLocation } from "react-router-dom";
import { formatTimeFromNow } from "../../../../utils/Dates";
import { EmptyComponent } from "../../../../shared/Empty/Empty";
import { UseIsDesktopOrLaptop } from "../../../../utils/Responsive/Responsive";

const MyMessages = ({
  setTitle,
  setShowChat,
  showChat,
  setCurrentChatId,
  currentChatId,
}) => {
  const [chatList, setChatList] = useState([]);
  const [jobData, setJobData] = useState(null);
  const [userData, setUserData] = useState(null);
  // const [currentChatId, setCurrentChatId] = useState(null);
  const [status, setStatus] = useState("");
  // const [showChat, setShowChat] = useState(false);
  const [chatCount, setChatCount] = useState(null);
  const userInfo = getUserInfo();
  const location = useLocation();
  const isDesktopOrLaptop = UseIsDesktopOrLaptop();
  const searchParams = new URLSearchParams(location.search);
  const chatId = searchParams.get("q");

  useEffect(() => {
    if (chatId) {
      setCurrentChatId(chatId);
    } else {
      setCurrentChatId(null);
      setShowChat(false);
    }
  }, [chatId]);

  const getAllChatList = async () => {
    setStatus(Status.PENDING);
    try {
      const res = await getAllChat();
      setChatList(res?.chatList || []);
      setJobData(res?.jobData || {});
      setUserData(res?.userData || {});
      setStatus(Status.IDLE);
    } catch (error) {
      setStatus(Status.REJECTED);
      ErrorToaster(error?.message);
    }
  };

  const getChatsCount = async () => {
    try {
      const res = await getChatCount();
      setChatCount(res || {});
      setStatus(Status.IDLE);
    } catch (error) {
      setStatus(Status.REJECTED);
      ErrorToaster(error?.message);
    }
  };

  useEffect(() => {
    getChatsCount();
    const interval = setInterval(() => {
      getChatsCount();
    }, 60000); // 6000 milliseconds = 1 minute

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    getAllChatList();
  }, []);

  const handleItemClick = async (chatId) => {
    setTitle(isDesktopOrLaptop ? "Message" : "All Chats");
    setCurrentChatId(chatId);
    const url = new URL(window.location.href);
    url.searchParams.set("q", chatId); // Replace 'q' with your query parameter key

    window.history.replaceState(null, "", url);
    setShowChat(true);
  };

  function limitText(text, maxLength = 20) {
    if (!text) return ""; // Handle case where text is null or undefined
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  }
  console.log("v", { chatList, userData, userInfo });

  return (
    <div className={isDesktopOrLaptop ? "mt30" : ""}>
      <div
        className="row mb40"
        style={{ margin: "-25px", height: "700px", marginTop: "10px" }}
      >
        {isDesktopOrLaptop ? (
          <>
            {/* Chat List Section */}
            <div className="col-lg-6 col-xl-5 col-xxl-4">
              <div className="message_container">
                <div className="inbox_user_list">
                  <div className="iu_heading pr35">
                    <div className="chat_user_search">
                      <form className="d-flex align-items-center">
                        <button className="btn" type="submit">
                          <span className="far fa-magnifying-glass"></span>
                        </button>
                        <input
                          className="form-control"
                          type="search"
                          placeholder="  Search..."
                          aria-label="Search"
                        />
                      </form>
                    </div>
                  </div>
                  {status === Status.PENDING ? (
                    <div style={{ marginRight: "150px" }}>
                      <Loader />
                    </div>
                  ) : (
                    <>
                      {chatList?.length > 0 ? (
                        <div
                          className="chat-member-list pr20"
                          style={{
                            maxHeight:
                              chatList?.length > 3
                                ? "560px"
                                : `${chatList?.length * 100}px`,
                            display: "flex",
                            flexDirection: "column",
                            overflowY: "auto",
                          }}
                        >
                          {chatList?.map((chat) => {
                            const isCurrentUserSender =
                              chat?.senderId === userInfo?._id;
                            const otherUserId = isCurrentUserSender
                              ? chat?.receiverId
                              : chat?.senderId;
                            const otherUserData = userData[otherUserId] || {};

                            return (
                              <div
                                className="list-item"
                                style={{
                                  backgroundColor:
                                    chat?.chatId === currentChatId
                                      ? "rgb(183 244 203)"
                                      : "transparent",
                                  borderRadius:
                                    chat?.chatId === currentChatId ? "5px" : "",
                                }}
                                key={chat?._id}
                                onClick={() => handleItemClick(chat?.chatId)}
                              >
                                <div className="d-flex align-items-center position-relative">
                                  <img
                                    className="mr10"
                                    src={
                                      otherUserData?.profilePicture ||
                                      "https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-cartoon-color-simple-male-avatar-png-image_1934459.jpg"
                                    }
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      objectFit: "cover",
                                      borderRadius: "50%",
                                      marginLeft: "5px",
                                    }}
                                    alt="User avatar"
                                  />
                                  <div className="d-sm-flex">
                                    <div className="d-inline-block">
                                      <div className="fz15 fw500 dark-color ff-heading mb-0">
                                        {limitText(otherUserData?.fullName)}
                                      </div>
                                      <p className="preview">
                                        {limitText(chat?.message)}
                                      </p>
                                    </div>
                                    <div className="iul_notific">
                                      <small
                                        style={{
                                          top: isDesktopOrLaptop
                                            ? "44px"
                                            : "15px",
                                        }}
                                      >
                                        {formatTimeFromNow(
                                          chat?.lastMessageTime || new Date()
                                        )}
                                      </small>
                                      {chatCount?.[chat?.chatId] && (
                                        <div
                                          className="m_notif online"
                                          style={{
                                            width: "25px",
                                            height: "25px",
                                            lineHeight: "23px",
                                            top: "15px",
                                          }}
                                        >
                                          <span className="fz16">
                                            {chatCount?.[chat?.chatId]}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        // {chatList?.length === 0 && (
                        <div
                          style={{
                            marginRight: "50px",
                            alignItems: "center",
                          }}
                        >
                          <EmptyComponent
                            description="Your chat history is empty."
                            type={true}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            {/* Chat Box Section */}
            {currentChatId || showChat ? (
              <ChatBox
                chatId={currentChatId || chatId}
                userData={userData}
                setTitle={setTitle}
              />
            ) : // <div
            //   style={{
            //     marginLeft: "415px",
            //     marginTop: "150px",
            //     position: "absolute",
            //     width: "50%",
            //   }}
            // >
            //   <EmptyComponent description="No Chat Found." type={true} />
            // </div>
            null}
          </>
        ) : currentChatId || showChat ? (
          <>
            <ChatBox
              chatId={currentChatId || chatId}
              userData={userData}
              setTitle={setTitle}
            />
          </>
        ) : (
          <div className="col-lg-6 col-xl-5 col-xxl-4">
            <div className="message_container">
              <div className="inbox_user_list">
                <div className="iu_heading pr35">
                  <div className="chat_user_search">
                    <form className="d-flex align-items-center">
                      <button className="btn" type="submit">
                        <span className="far fa-magnifying-glass"></span>
                      </button>
                      <input
                        className="form-control"
                        type="search"
                        placeholder="  Search..."
                        aria-label="Search"
                      />
                    </form>
                  </div>
                </div>
                {status === Status.PENDING ? (
                  <div style={{ marginRight: "150px" }}>
                    <Loader />
                  </div>
                ) : (
                  <>
                    {chatList?.length > 0 ? (
                      <div
                        className="chat-member-list pr20"
                        style={{
                          maxHeight:
                            chatList?.length > 3
                              ? "560px"
                              : `${chatList?.length * 100}px`,
                          display: "flex",
                          flexDirection: "column",
                          overflowY: "auto",
                        }}
                      >
                        {chatList?.map((chat) => (
                          <div
                            className="list-item"
                            style={{
                              backgroundColor:
                                chat?.chatId === currentChatId
                                  ? "rgb(183 244 203)"
                                  : "transparent",
                              borderRadius:
                                chat?.chatId === currentChatId ? "5px" : "",
                            }}
                            key={chat?._id}
                            onClick={() => handleItemClick(chat?.chatId)}
                          >
                            <div className="d-flex align-items-center position-relative">
                              <img
                                className="mr10"
                                src={
                                  userInfo?.userType === "photographer"
                                    ? userData[chat?.senderId]
                                        ?.profilePicture ||
                                      "https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-cartoon-color-simple-male-avatar-png-image_1934459.jpg"
                                    : userData[chat?.receiverId]
                                        ?.profilePicture ||
                                      "https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-cartoon-color-simple-male-avatar-png-image_1934459.jpg"
                                }
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  objectFit: "cover",
                                  borderRadius: "50%",
                                  marginLeft: "5px",
                                }}
                                alt="User avatar"
                              />
                              <div className="d-sm-flex">
                                <div className="d-inline-block">
                                  <div className="fz15 fw500 dark-color ff-heading mb-0">
                                    {userInfo?.userType === "photographer"
                                      ? limitText(
                                          userData[chat?.senderId]?.fullName
                                        )
                                      : limitText(
                                          userData[chat?.receiverId]?.fullName
                                        )}
                                  </div>
                                  <p className="preview">
                                    {limitText(jobData[chat?.jobId])}
                                  </p>
                                </div>
                                <div className="iul_notific">
                                  <small
                                    style={{
                                      top: isDesktopOrLaptop ? "44px" : "15px",
                                    }}
                                  >
                                    {formatTimeFromNow(
                                      chat?.lastMessageTime || new Date()
                                    )}
                                  </small>
                                  {chatCount?.[chat?.chatId] && (
                                    <div
                                      className="m_notif online"
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        lineHeight: "23px",
                                        top: "15px",
                                        position: "absolute",
                                        right: 0,
                                      }}
                                    >
                                      <span className="fz14">
                                        {chatCount?.[chat?.chatId]}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div
                        style={{
                          marginRight: "50px",
                          alignItems: "center",
                        }}
                      >
                        <EmptyComponent
                          description="Your chat history is empty."
                          type={true}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyMessages;
