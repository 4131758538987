import React, { useEffect, useRef, useState } from "react";
import { getOneChat } from "../../../../API/ChatApi";
import { getUserInfo } from "../../../../common/getUserInfo";
import { Status } from "../../../../constants/constant";
import { ErrorToaster } from "../../../../shared/Toasters/toaster";
import { io } from "socket.io-client";
import { formatDateWithTime } from "../../../../utils/Dates";
import Loader from "../../../../utils/Loader";
import { Link } from "react-router-dom";
import { UseIsDesktopOrLaptop } from "../../../../utils/Responsive/Responsive";

const socket = io("https://api.topshotphotographer.com/");

const ChatBox = ({ chatId, userData, setTitle }) => {
  const [chatMessages, setChatMessages] = useState([]);
  const [status, setStatus] = useState("");
  const [totalMessagesHeight, setTotalMessagesHeight] = useState(0);
  const userInfo = getUserInfo();

  useEffect(() => {
    setTitle(isDesktopOrLaptop ? "Message" : "All Chats");
  }, []);

  const isDesktopOrLaptop = UseIsDesktopOrLaptop();
  const chatContainerRef = useRef(null);
  const messageInputRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages]);

  const scrollToBottom = () => {
    if (chatContainerRef?.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  const chatListRef = useRef(null);

  useEffect(() => {
    const scrollToBottom = () => {
      chatListRef.current.scrollTop = chatListRef.current.scrollHeight;
    };
    scrollToBottom();
  }, [chatMessages]);

  useEffect(() => {
    const calculateTotalHeight = () => {
      const messages = document.querySelectorAll(".chatting_content li");
      const totalHeight = Array.from(messages).reduce(
        (acc, curr) => acc + curr.offsetHeight,
        0
      );
      setTotalMessagesHeight(totalHeight);
    };
    calculateTotalHeight();
  }, [chatMessages]);

  useEffect(() => {
    socket.emit("subscribe", chatId);

    socket.on("conversations", (data) => {
      setChatMessages((prevMessages) => [...prevMessages, data]);
    });

    return () => {
      socket.off("conversations");
    };
  }, [chatId]);

  const sendMessage = (message) => {
    socket.emit("message", {
      room: message?.chatId,
      message: message,
    });
  };

  const getCurrentChatDetails = async () => {
    setStatus(Status.PENDING);
    try {
      const res = await getOneChat(chatId);
      setChatMessages(res);
      setStatus(Status.IDLE);
    } catch (error) {
      setStatus(Status.REJECTED);
      ErrorToaster(error?.message);
    }
  };

  useEffect(() => {
    if (chatId) {
      getCurrentChatDetails();
    }
  }, [chatId]);

  const handleSubmit = ({ message }) => {
    const formData = {
      senderId: userInfo?._id,
      message,
      chatId,
      rId:
        chatMessages[0]?.receiverId === userInfo?._id
          ? chatMessages[0]?.senderId
          : chatMessages[0]?.receiverId,
      jobId: chatMessages[0]?.jobId,
      attachment: "",
    };

    if (message) {
      sendMessage(formData);
      messageInputRef.current.value = "";
    }
  };

  const handleKeyDown = (e) => {
    const message = e.target.value.trim();
    if (e.key === "Enter" && message) {
      e.preventDefault(); // To prevent form submission on Enter key
      handleSubmit({ message });
    }
  };

  return (
    <div className="col-lg-6 col-xl-5 col-xxl-8">
      {status === Status.PENDING ? (
        <div style={{ marginRight: "150px", marginTop: "100px" }}>
          <Loader />
        </div>
      ) : (
        <div
          className="message_container"
          style={{ minHeight: isDesktopOrLaptop ? "" : "685px" }}
          ref={chatListRef}
        >
          {/* User header and job details link */}
          <div
            className="d-flex justify-content-between user_heading px-0 mx30"
            style={{ padding: "10px 0px 0px" }}
          >
            <div className="wrap">
              <img
                className="rounded-circle mx-auto"
                style={{
                  width: "40px",
                  height: "40px",
                  objectFit: "cover",
                  borderRadius: "50%",
                  marginTop: "5px",
                }}
                src={
                  userData
                    ? userData[
                        userInfo?.userType === "photographer"
                          ? chatMessages[0]?.senderId
                          : chatMessages[0]?.receiverId
                      ]?.profilePicture ||
                      "https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-cartoon-color-simple-male-avatar-png-image_1934459.jpg"
                    : "https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-cartoon-color-simple-male-avatar-png-image_1934459.jpg"
                }
                alt="user"
              />
              <div className="meta d-sm-flex justify-content-sm-between align-items-center p5">
                <div
                  // className="authors"
                  className={`authors ${isDesktopOrLaptop ? "" : "ml40"}`}
                >
                  <h6 className={`name mb-0}`}>
                    {userData &&
                      userData[
                        userInfo?.userType === "photographer"
                          ? chatMessages[0]?.senderId
                          : chatMessages[0]?.receiverId
                      ]?.fullName}
                  </h6>
                  <p className="preview">Active</p>
                </div>
              </div>
            </div>
            <div className="bdrb1 pb15 mb30 d-flex justify-content-between align-items-center">
              <Link
                to={`/${
                  userInfo?.userType === "photographer" ? "jobs" : "my-jobs"
                }/${chatMessages[0]?.jobId}`}
              >
                <span
                  className="text-decoration-underline fz14 fw500 text-red ff-heading cursor-pointer"
                  style={{ cursor: "pointer" }}
                >
                  Job Details
                </span>
              </Link>
            </div>
          </div>

          {/* Message list */}
          <div className="inbox_chatting_box">
            <ul className="chatting_content">
              {chatMessages
                ?.filter((chat) => chat?.chatId === chatId)
                ?.map((msg, i) => (
                  <li
                    key={i}
                    className={
                      msg.senderId !== userInfo?._id
                        ? "sent float-start"
                        : "reply float-end"
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems:
                          msg.senderId !== userInfo?._id
                            ? "flex-start"
                            : "flex-end",
                        marginBottom: "10px",
                      }}
                    >
                      <p
                        style={{
                          padding: "8px 15px",
                          backgroundColor:
                            msg.senderId !== userInfo?._id
                              ? "rgb(183 244 203)"
                              : "rgb(210 247 224)",
                          borderRadius:
                            msg.senderId !== userInfo?._id
                              ? "15px 15px 15px 0"
                              : "15px 15px 0 15px",
                          maxWidth: "70%",
                          wordBreak: "break-word",
                          whiteSpace: "pre-wrap",
                          textAlign: "left",
                        }}
                      >
                        {msg?.message}
                      </p>
                      <small
                        className="timeStamp"
                        style={{
                          fontSize: "11px",
                          color: "grey",
                          marginTop: "3px",
                        }}
                      >
                        {formatDateWithTime(msg?.date || new Date())}
                      </small>
                    </div>
                  </li>
                ))}
            </ul>
          </div>

          {/* Message input */}
          <div className="message_input">
            <form
              className="d-flex align-items-center"
              style={{ width: isDesktopOrLaptop ? "88%" : "70%" }}
              onSubmit={(e) => {
                e.preventDefault(); // Prevent default form submission behavior
                handleSubmit({
                  message: e.target.elements.message.value.trim(),
                }); // Explicitly call handleSubmit on submit
              }}
            >
              <div
                className="flex-grow-1"
                style={{ paddingTop: "3px", marginLeft: "1px" }}
              >
                <textarea
                  className="form-control"
                  name="message"
                  placeholder="Type a Message"
                  aria-label="Search"
                  ref={messageInputRef}
                  onKeyDown={handleKeyDown}
                />
              </div>
              <div className="ml5">
                <button
                  className="btn ud-btn btn-thm"
                  style={{
                    height: "56px",
                    position: "absolute",
                    right: "0px",
                    top: "2px",
                  }}
                >
                  Send <i className="fal fa-arrow-right-long"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatBox;
