import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  ErrorToaster,
  SuccessToaster,
} from "../../../../shared/Toasters/toaster";
import Loading from "../../../../utils/Loader";
import { forgotPassword } from "../../../../API/AuthApi";

export const ForgetPassword = () => {
  const location = useLocation();

  const { userType } = location.state || {};
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const forgetPassword = async (data) => {
    setIsLoading(true);
    try {
      const res = await forgotPassword(data);
      if (res.status === 200) {
        SuccessToaster("Reset Password Link Has Been Sent To Your Email");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      ErrorToaster(
        "The email address you entered does not exist in our system. Please check the email address and try again."
      );
    }
  };

  useEffect(() => {
    setValue("userType", userType);
  });

  return (
    <>
      <div className="body_content" id="container">
        <section>
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 m-auto wow fadeInUp mt-2"
                data-wow-delay="300ms"
              >
                <div className="main-title text-center mb500">
                  <h2
                    className="title"
                    style={{
                      color: "var(--headings-color)",
                      fontFamily: "var(--title-font-family)",
                      lineHeight: 1.3125,
                      marginInlineEnd: "14px",
                    }}
                  >
                    Reset Password
                  </h2>
                  <p className="paragraph fz16 mt15">
                    Please enter your email address and click "Send Email"
                  </p>
                </div>
              </div>
            </div>
            <div
              className="row wow fadeInRight mb200"
              style={{ marginBottom: "200px" }}
              data-wow-delay="300ms"
            >
              <div className="col-xl-6 mx-auto">
                <div className="log-reg-form search-modal form-style1 bgc-white p50 p30-sm default-box-shadow1 bdrs12">
                  <p className="text">
                    Already Have An Account?{" "}
                    <Link to={`/login/${userType}`} className="text-thm">
                      Sign In!
                    </Link>
                  </p>
                  {/* <div onClick={() => navigate(-1)} className="mt-10">
                    <img
                      width="50"
                      height="50"
                      src="https://img.icons8.com/plasticine/100/long-arrow-left.png"
                      alt="long-arrow-left"
                    />
                  </div> */}
                  <div className="mb-3">
                    <div className="mb30">
                      <div className="d-flex justify-content-between fz16 mt15">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="photographer"
                            id="photographer"
                            value="photographer"
                            {...register("userType", { required: true })}
                          />
                          <label
                            className="form-check-label fw500 dark-color"
                            htmlFor="photographer"
                          >
                            Photographer
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="user"
                            id="user"
                            value="user"
                            {...register("userType", { required: true })}
                            // checked={userType === 'user'}
                            // onChange={handleRadioChange}
                          />
                          <label
                            className="form-check-label fw500 dark-color"
                            htmlFor="user"
                          >
                            Job Poster
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="agency"
                            id="agency"
                            value="agency"
                            {...register("userType", { required: true })}
                            // checked={userType === 'agency'}
                            // onChange={handleRadioChange}
                          />
                          <label
                            className="form-check-label fw500 dark-color"
                            htmlFor="agency"
                          >
                            Agency
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <form noValidate onSubmit={handleSubmit(forgetPassword)}>
                    <div className="mb-3">
                      <label
                        htmlFor="email"
                        className="form-label fw500 dark-color mt-1"
                      >
                        Email
                      </label>
                      <input
                        id="email"
                        {...register("email", {
                          required: "Email Is Eequired",
                          pattern: {
                            value: /\b[\w.-]+@[\w.-]+\.\w{2,4}\b/gi,
                            message: "Email Not Valid",
                          },
                        })}
                        type="email"
                        className="form-control"
                        placeholder="Please Enter Your Email"
                      />
                      {errors.email && (
                        <p className="text-danger fw500">
                          {errors.email.message}
                        </p>
                      )}
                      {/* {!!status && status === 'success' && (
                        <p className="text-success fw500 text-capitalize">
                          {message}
                        </p>
                      )} */}
                      {/* {!!error && (
                        <p className="text-danger fw500 text-capitalize">
                          {isError}
                        </p>
                      )} */}
                    </div>
                    <div className="d-grid mb30 mt-0">
                      <button className="ud-btn btn-thm" type="submit">
                        {isLoading ? <Loading type="button" /> : "Send Email"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
