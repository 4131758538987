import { Modal } from "react-bootstrap";

export function Confirmation(props) {
  const { show, onClose, onConfirmation } = props;

  return (
    <Modal centered size="lg" backdrop="static" show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title className="heading-color ff-heading">
          Confirmation
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text" style={{ fontWeight: "500", fontSize: "18px" }}>
          Once you accept the proposal from this photographer, a chat window
          will be created for you to communicate with each other. Would you like
          to proceed?{" "}
        </p>
        <div className="d-flex justify-content-end">
          <button
            className="login-info home18-sign-btn px15 ml10 bdr1-dark mr10"
            style={{ width: "80px", borderRadius: "5px" }}
            onClick={onClose}
          >
            Close
          </button>
          <button
            className="mr5 ud-btn add-joining home20-join-btn text-white px15 ml10 mr10"
            onClick={onConfirmation}
          >
            <span
              style={{ cursor: "pointer", marginTop: "2px", fontSize: "18px" }}
            >
              Yes
            </span>
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
