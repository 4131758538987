import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import OtpVerificationModal from "../../shared/OtpVerification/OtpVerification";
import { useState } from "react";
import { ErrorToaster, SuccessToaster } from "../../shared/Toasters/toaster";
import { updateUserEmailStart, updateUserMobileStart } from "../../API/UserApi";
import { Status } from "../../constants/constant";
import PhoneInput from "../../shared/PhoneInput/PhoneInput";

const EditModal = ({ editType, showModal, handleClose, setShowModal }) => {
  const [show, setShow] = useState(false);
  const [formValue, setFormValue] = useState(null);
  const [status, setStatus] = useState("");
  const [error, setError] = useState("");

  const handleCloseOTPModal = () => {
    setShow(false);
  };
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const updateEmailStart = async (data) => {
    setStatus(Status.PENDING);
    try {
      const res = await updateUserEmailStart(data);
      if (res) {
        setShow(true);
        SuccessToaster(res);
      }
      setStatus(Status.IDLE);
    } catch (error) {
      setStatus(Status.REJECTED);
      ErrorToaster(error?.message);
      setError(error?.message);
    }
  };

  const updateMobileStart = async (data) => {
    setStatus(Status.PENDING);
    try {
      const res = await updateUserMobileStart(data);
      if (res) {
        setShow(true);
        SuccessToaster(res);
      }
      setStatus(Status.IDLE);
    } catch (error) {
      setStatus(Status.REJECTED);
      ErrorToaster(error?.message);
      setError(error?.message);
    }
  };

  const handleUpdate = async (data) => {
    setFormValue(data);
    if (editType === "email") {
      await updateEmailStart(data);
    } else {
      await updateMobileStart(data);
    }
  };
  return (
    <>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        {show ? (
          <OtpVerificationModal
            userData={formValue}
            show={show}
            handleClose={handleCloseOTPModal}
            type={editType}
            setShow={setShow}
            setShowEditModal={setShowModal}
          />
        ) : (
          <>
            <Modal.Header closeButton>
              <Modal.Title style={{ textTransform: "capitalize" }}>
                Edit {editType}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="" style={{ width: "400px" }}>
              <form noValidate onSubmit={handleSubmit(handleUpdate)}>
                {editType === "email" && (
                  <div className="mb-3">
                    <label
                      htmlFor="email"
                      className="form-label fw500 fz16 dark-color text-capitalize"
                    >
                      Email
                    </label>
                    <input
                      id="email"
                      {...register("email", {
                        pattern: {
                          value: /\b[\w.-]+@[\w.-]+\.\w{2,4}\b/gi,
                          message: "email not valid",
                        },
                      })}
                      type="email"
                      className="form-control"
                      placeholder={`please enter your email`}
                    />
                    {errors.email && (
                      <p className="text-danger fw500">
                        {errors.email.message}
                      </p>
                    )}
                  </div>
                )}
                {editType === "mobile" && (
                  <PhoneInput
                    label="Add Mobile Number"
                    register={register}
                    errors={errors}
                    setValue={setValue}
                  />
                )}
                <div className="mb-3">
                  <label
                    htmlFor="password"
                    className="form-label fz16 fw500 dark-color"
                  >
                    Password
                  </label>
                  <input
                    id="password"
                    {...register("password", {
                      required: "password is required",
                      pattern: {
                        value:
                          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm,
                        message: `- at least 8 characters\n
                      - must contain at least 1 uppercase letter, 1 lowercase letter, and 1 number\n
                      - Can contain special characters`,
                      },
                    })}
                    type="password"
                    className="form-control"
                    placeholder="please enter your password"
                  />
                  {errors.password && (
                    <p className="text-danger fw500">
                      {errors.password.message}
                    </p>
                  )}
                </div>
                <div className="col-md-12">
                  <div className="text-start">
                    <Button className="ud-btn btn-thm" type="submit">
                      Save
                      <i className="fal fa-arrow-right-long"></i>
                    </Button>
                  </div>
                </div>
              </form>
            </Modal.Body>
          </>
        )}
      </Modal>
    </>
  );
};

export default EditModal;
