import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getToken } from "../../common/getToken";
import { logoutUser } from "../../common/logOut";

const publicPaths = [
  "/",
  "/photographers",
  "/jobs",
  "/subscriptions",
  "/login/photographer",
  "/login/job-poster",
  "/login/agency",
  "/login/admin",
  "/signup/photographer",
  "/signup/job-poster",
  "/signup/agency",
  "/forget-password",
  "/execute-forget-password",
];
const useAuthCheck = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const checkToken = async () => {
      const token = getToken();
      if (!token && !publicPaths.includes(location.pathname)) {
        await logoutUser();
        navigate("/"); // Redirect to login page
      }
    };

    checkToken();
  }, [location, navigate, publicPaths]);
};

export default useAuthCheck;
