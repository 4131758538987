import React, { useCallback, useState } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { categories } from "../../../dummyData";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { useEffect } from "react";
import { ErrorToaster, SuccessToaster } from "../../../shared/Toasters/toaster";
import { myJobDetails, updateJob } from "../../../API/JobsApi";
import { useNavigate, useParams } from "react-router-dom";
import { Status } from "../../../constants/constant";
import MapComponent from "../../../shared/MapComponent/MapComponent";
import { getUserInfo } from "../../../common/getUserInfo";
import TextEditor from "../../../shared/TextEditor";

const initialValues = {
  title: "",
  noOfPhotographers: "",
  category: "",
  location: "",
  budgetType: "",
  jobRate: "",
  durationType: "",
  jobDuration: "",
  jobDateTime: null,
  jobEndDateTime: null,
  multipleCameraRequired: false,
  videographyNeeded: false,
  digitalFilesRequired: false,
  businessInsuranceRequired: false,
  description: "",
};

const EditJob = () => {
  const { id } = useParams();
  const [selectedStartDateTime, setSelectedStartDateTime] = useState(null);
  const [selectedEndDateTime, setSelectedEndDateTime] = useState(null);
  const navigate = useNavigate();
  const userInfo = getUserInfo();
  const [jobDetails, setJobDetails] = useState(null);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const budgetType = watch("budgetType");
  const durationType = watch("durationType");
  const jobDescription = watch("description");

  const updateJobDetails = async (data) => {
    const startDateStr = data?.jobDateTime;
    const endDateStr = data?.jobEndDateTime;
    const startDateObj = new Date(startDateStr);
    const endDateObj = new Date(endDateStr);

    const startTimestamp = startDateObj.getTime();
    const endTimestamp = endDateObj.getTime();

    const formValue = {
      ...data,
      jobId: id,
      jobDateTime: startTimestamp,
      jobEndDateTime: endTimestamp,
    };
    try {
      const res = await updateJob(formValue);
      if (res) {
        SuccessToaster("Job Updated Successfully!");
        reset();
        setTimeout(() => {
          navigate("/my-jobs");
        }, 2000);
      }
    } catch (error) {
      ErrorToaster(error?.message);
    }
  };
  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const filterEndTime = (time) => {
    if (!selectedStartDateTime) return true;
    const startDateTime = new Date(selectedStartDateTime);
    const currentTime = new Date(time);
    return currentTime > startDateTime;
  };

  const getJobDetails = useCallback(
    async (jobId) => {
      try {
        let data;
        // Conditional fetching based on user type and pathName
        data = await myJobDetails(jobId);
        // Check if data is received before setting it
        if (data) {
          setJobDetails(data);
        } else {
          throw new Error("No job details found.");
        }
      } catch (error) {
        ErrorToaster(error?.message);
      }
    },
    [userInfo]
  );

  useEffect(() => {
    if (id) {
      getJobDetails(id);
    }
  }, [id]);

  console.log("jobsss", jobDetails);
  useEffect(() => {
    if (!jobDetails) return;
    // Directly set values for properties on the root level of jobDetails
    // Loop through each key in initialValues and set it if it exists in jobDetails
    Object.keys(initialValues).forEach((key) => {
      if (key in jobDetails) {
        // Handle date fields
        if (key === "jobDateTime" || key === "jobEndDateTime") {
          setSelectedStartDateTime();
          setValue(key, new Date(jobDetails[key]));
        } else {
          setValue(key, jobDetails[key]);
        }
      } else if (key in jobDetails.additionInfo) {
        // Set nested fields in additionInfo
        setValue(key, jobDetails.additionInfo[key]);
      }
    });
  }, [setValue, jobDetails]);

  const handleCancelClick = () => {
    reset();
    navigate("/my-jobs");
  };

  const handleEditorChange = (content) => {
    setValue("description", content);
  };

  const handleEditorInit = () => {
    const toolbar = document.querySelector(".tox-tinymce-aux");
    const modalBody = document.querySelector(".modal-body");
    if (toolbar && modalBody) {
      modalBody.appendChild(toolbar);
    }
  };

  return (
    <div className="ps-widget bgc-white bdrs4 p30 mb30 overflow-hidden position-relative mt10">
      <div className="col-lg-9">
        <form
          className="form-style1"
          noValidate
          onSubmit={handleSubmit(updateJobDetails)}
        >
          <div className="row">
            <div className="col-sm-6">
              <div className="mb20">
                <label className="heading-color ff-heading fw500 mb10">
                  Job Title
                  <span className="required-field">*</span>
                </label>
                <input
                  id="title"
                  {...register("title", {
                    required: "Title is required",
                  })}
                  type="text"
                  className="form-control"
                  placeholder="Example: Senior Fashion Photographer"
                />
                {errors.title && (
                  <p className="text-danger">{errors.title.message}</p>
                )}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb20">
                <div className="form-style1">
                  <label className="heading-color ff-heading fw500 mb10">
                    Please Specify How Many Photographers Are Required
                    <span className="required-field">*</span>
                  </label>
                  <input
                    {...register("noOfPhotographers", {
                      required: "No of Photographers is required",
                    })}
                    type="number"
                    className="form-control"
                    placeholder="Enter Number of Photographers Required"
                  />
                  {errors.noOfPhotographers && (
                    <p className="text-danger">
                      {errors.noOfPhotographers.message}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="mb20">
                <div className="form-style1">
                  <label className="heading-color ff-heading fw500 mb10">
                    Job Category
                    <span className="required-field">*</span>
                  </label>
                  <div className="bootselect-multiselect">
                    <select
                      className="form-control"
                      {...register("category", {
                        multiple: true,
                      })}
                      // onChange={handleChange}
                    >
                      {errors.category && (
                        <p className="text-danger">{errors.category.message}</p>
                      )}
                      <option value="" selected disabled>
                        Select Category
                        <span className="required-field">*</span>
                      </option>
                      {categories.map((category, index) => (
                        <option key={index} value={category}>
                          {category}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="mb20">
                <div className="form-style1">
                  <label className="heading-color ff-heading fw500 mb10">
                    Location
                    <span className="required-field">*</span>
                  </label>
                  <MapComponent
                    name="location"
                    register={register}
                    setValue={setValue}
                    // defaultValue={userInfo?.location}
                    placeholder="Enter Shoot Location"
                  />
                  {errors.location && (
                    <p className="text-danger">{errors.location.message}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb20 align-items-center">
                <label className="heading-color ff-heading fw500 mb10">
                  Budget Type
                  <span className="required-field">*</span>
                </label>
                <div className="input-group flex-grow-1">
                  <select
                    className="form-control"
                    id="budgetType"
                    {...register("budgetType", {
                      required: "Budget type is required",
                    })} // Replace 'yourSelectName' with your select/dropdown name
                  >
                    {errors.budgetType && (
                      <p className="text-danger">{errors.budgetType.message}</p>
                    )}
                    <option value="" selected disabled>
                      Select
                    </option>
                    <option value="hourly">Hourly</option>
                    <option value="fixed">Fixed</option>
                  </select>
                </div>
              </div>
            </div>
            {budgetType === "hourly" ? (
              <div className="col-sm-6">
                <div className="mb20">
                  <div className="form-style1">
                    <label className="heading-color ff-heading fw500 mb10">
                      Hourly Rate
                      <span className="required-field">*</span>
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">$</span>
                      <input
                        {...register("jobRate", {
                          required: "jobRate is required",
                        })}
                        type="number"
                        className="form-control"
                        placeholder="Enter Hourly Rate"
                      />
                    </div>
                    {errors.jobRate && (
                      <p className="text-danger">{errors.jobRate.message}</p>
                    )}
                  </div>
                </div>
              </div>
            ) : budgetType === "fixed" ? (
              <div className="col-sm-6">
                <div className="mb20">
                  <div className="form-style1">
                    <label className="heading-color ff-heading fw500 mb10">
                      Fixed Amount
                      <span className="required-field">*</span>
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">$</span>
                      <input
                        {...register("jobRate", {
                          required: "jobRate is required",
                        })}
                        type="number"
                        className="form-control"
                        placeholder="Enter Fixed Amount"
                      />
                    </div>
                    {errors.jobRate && (
                      <p className="text-danger">{errors.jobRate.message}</p>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-sm-6"></div>
            )}
            <div className="col-sm-6">
              <label className="heading-color ff-heading fw500 mb10">
                Shoot Duration Type
                <span className="required-field">*</span>
              </label>
              <div className="bootselect-multiselect">
                <select
                  className="form-control"
                  {...register("durationType", {
                    required: "Duration Type is required",
                  })}
                  // onChange={handleChange}
                >
                  {errors.durationType && (
                    <p className="text-danger">{errors.durationType.message}</p>
                  )}
                  <option value="" disabled selected>
                    Select Shoot Type
                    <span className="required-field">*</span>
                  </option>
                  <option value="days">Days</option>
                  <option value="hours">Hours</option>
                </select>
              </div>
            </div>
            {durationType === "days" ? (
              <div className="col-sm-6">
                <label className="heading-color ff-heading fw500 mb10">
                  Shoot Duration
                  <span className="required-field">*</span>
                </label>
                <input
                  {...register("jobDuration", {
                    required: "Job Duration is required",
                    pattern: {
                      value: /^[1-9]\d*$/, // Regex to ensure positive integers only
                      message: "Enter a valid positive integer",
                    },
                  })}
                  type="number"
                  pattern="[0-9]*"
                  className="form-control"
                  placeholder="Enter shoot duration (Days)"
                />
                {errors.jobDuration && (
                  <p className="text-danger">{errors.jobDuration.message}</p>
                )}
              </div>
            ) : durationType === "hours" ? (
              <div className="col-sm-6">
                <label className="heading-color ff-heading fw500 mb10">
                  Shoot Duration
                  <span className="required-field">*</span>
                </label>
                <input
                  {...register("jobDuration", {
                    required: "Job Duration is required",
                    min: { value: 1, message: "Minimum value is 1" },
                    max: {
                      value: 24,
                      message: "Enter value less than 24",
                    },
                    pattern: {
                      value: /^[1-9]\d*$/, // Regex to ensure positive integers only
                      message: "Enter a valid positive integer",
                    },
                  })}
                  type="number"
                  pattern="[0-9]*"
                  className="form-control"
                  placeholder="Enter shoot duration (Hours)"
                />
                {errors.jobDuration && (
                  <p className="text-danger">{errors.jobDuration.message}</p>
                )}
              </div>
            ) : (
              <div className="col-sm-6"></div>
            )}

            <div className="col-sm-6">
              <div className="mb20">
                <div className="form-style1" style={{ marginTop: "25px" }}>
                  <label className="heading-color ff-heading fw500 mb10">
                    Job Start Date & Time
                    <span className="required-field">*</span>
                  </label>
                  <br />
                  <DatePicker
                    selected={selectedStartDateTime ?? watch("jobDateTime")}
                    onChange={(date) => setValue("jobDateTime", date)}
                    showTimeSelect
                    timeFormat="hh:mm aa"
                    timeIntervals={30}
                    timeCaption="Time"
                    dateFormat="yyyy-MM-dd hh:mm aa"
                    className="form-control customDatePicker"
                    placeholderText="Select Start Date And Time"
                    minDate={new Date()}
                    filterTime={filterPassedTime}
                    required
                  />

                  {errors.jobDateTime && (
                    <p className="text-danger fw500">
                      {errors.jobDateTime.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb20">
                <div className="form-style1" style={{ marginTop: "25px" }}>
                  <label className="heading-color ff-heading fw500 mb10">
                    Job End Date & Time
                    <span className="required-field">*</span>
                  </label>
                  <br />
                  <DatePicker
                    selected={selectedEndDateTime ?? watch("jobEndDateTime")}
                    onChange={(date) => setValue("jobEndDateTime", date)}
                    showTimeSelect
                    timeFormat="hh:mm aa"
                    timeIntervals={30}
                    timeCaption="Time"
                    dateFormat="yyyy-MM-dd hh:mm aa"
                    minDate={selectedStartDateTime} // Set the minimum date to be the selected start date
                    className="form-control customDatePicker"
                    placeholderText="Select End Date And time"
                    filterTime={filterEndTime}
                    style={{ width: "380px" }}
                  />
                  {errors.jobDateTime && (
                    <p className="text-danger fw500">
                      {errors.jobDateTime.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb10">
                <label className="custom_checkbox heading-color fw500 fz16 ff-heading">
                  Is Studio Space Required?
                  <input
                    type="checkbox"
                    id="multipleCameraRequired"
                    {...register("multipleCameraRequired")}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb10">
                <label className="custom_checkbox heading-color fw500 fz16 ff-heading">
                  Is Videography Required?
                  <input
                    type="checkbox"
                    id="videographyNeeded"
                    {...register("videographyNeeded")}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb10">
                <label className="custom_checkbox heading-color fw500 fz16 ff-heading">
                  Are digital files required?
                  <input
                    type="checkbox"
                    id="digitalFilesRequired"
                    {...register("digitalFilesRequired")}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb10">
                <label className="custom_checkbox heading-color fw500 fz16 ff-heading">
                  Is photographer's business insurance required?
                  <input
                    type="checkbox"
                    id="businessInsuranceRequired"
                    {...register("businessInsuranceRequired")}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb10">
                <label className="heading-color ff-heading fw500 mb10">
                  Job Description
                  <span className="required-field">*</span>
                </label>
                {/* <textarea
                  {...register("description", {
                    required: "Job Description is required",
                  })}
                  cols="30"
                  rows="6"
                  placeholder="Write a job description..."
                /> */}
                <TextEditor
                  onChange={(tabId, content) => handleEditorChange(content)}
                  initialValue={jobDescription}
                  handleEditorInit={handleEditorInit}
                />
                {errors.description && (
                  <p className="text-danger fw500">
                    {errors.description.message}
                  </p>
                )}
              </div>
            </div>

            <div className="d-flex col-md-12">
              <div className="text-start">
                <Button
                  className="ud-btn btn-thm"
                  type="submit"
                  // onClick={handleNextClick}
                  // disabled={
                  //   userInfo?.profileStatus === 'pending for review'
                  // }
                >
                  Edit A Job
                  <i className="fal fa-arrow-right-long"></i>
                </Button>
              </div>
              <div className="text-start ml20">
                <Button
                  className="ud-btn btn-transparent mb25 me-4"
                  type="submit"
                  onClick={handleCancelClick}
                >
                  Cancel
                  <i className="fal fa-arrow-right-long"></i>
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditJob;
