import React from "react";
import PostJob from "../../features/job/components/PostJob";
import SideMenu from "../../shared/SideMenu/SideMenu";
import { UseIsDesktopOrLaptop } from "../../utils/Responsive/Responsive";
import EditJob from "../../features/job/components/EditJob";

const EditJobPage = () => {
  const isDesktopOrLaptop = UseIsDesktopOrLaptop();
  return (
    <div className="dashboard_content_wrapper" style={{ marginTop: "-35px" }}>
      <div className="dashboard dashboard_wrapper pr30 pr0-xl">
        <SideMenu />
        <div
          className={isDesktopOrLaptop ? "dashboard__main" : ""}
          style={{ marginTop: "50px" }}
        >
          <div
            className="dashboard__content hover-bgc-color"
            style={{ padding: "26px 15px 20px" }}
          >
            <div className="row pb40">
              <div className="col-lg-12 pb10">
                <div className="dashboard_title_area">
                  <h5>Edit A Job</h5>
                  <p className="text"></p>
                </div>
              </div>
              <EditJob />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditJobPage;
