/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useState } from "react";
import { getUserInfo } from "../../common/getUserInfo";
import { Link, useLocation } from "react-router-dom";
import { Status, UserTypes } from "../../constants/constant";
import { logoutUser } from "../../common/logOut";

const MobileSideBar = () => {
  const [showSideBar, setShowSideBar] = useState(false);
  const location = useLocation();
  const pathName = location.pathname;
  const userInfo = getUserInfo();

  const memoizedMenu = useMemo(() => {
    if (userInfo?.userType === UserTypes.PHOTOGRAPHER) {
      return (
        <>
          <div className="sidebar_list_item">
            <Link
              to="/jobs"
              className={`items-center ${
                pathName === "/jobs" ? "-is-active" : ""
              }`}
            >
              <i className="flaticon-home mr15"></i>Home
            </Link>
          </div>
          <div className="sidebar_list_item">
            <Link
              to="/portfolios"
              className={`items-center ${
                pathName === "/portfolios" ? "-is-active" : ""
              }`}
            >
              <i className="flaticon-document mr15"></i>Manage Portfolio
            </Link>
          </div>
          {userInfo?.profileStatus === Status.COMPLETED && (
            <>
              <div className="sidebar_list_item">
                <Link
                  to="/photographer-jobs"
                  className={`items-center ${
                    pathName === "/photographer-jobs" ? "-is-active" : ""
                  }`}
                >
                  <i className="flaticon-like mr15"></i>My Jobs
                </Link>
              </div>
              <div className="sidebar_list_item">
                <Link
                  to="/my-plan"
                  className={`items-center ${
                    pathName === "/my-plan" ? "-is-active" : ""
                  }`}
                >
                  <i className="flaticon-document mr15"></i>Billing
                </Link>
              </div>
              <div className="sidebar_list_item">
                <Link
                  to="/messages"
                  className={`items-center ${
                    pathName === "/messages" ? "-is-active" : ""
                  }`}
                >
                  <i className="flaticon-chat  mr15"></i>
                  Messages
                </Link>
              </div>
              <div className="sidebar_list_item">
                <Link
                  to="/change-password"
                  className={`items-center ${
                    pathName === "/change-password" ? "-is-active" : ""
                  }`}
                >
                  <i className="fa-sharp fa-light fa-gear mr15"></i>
                  Change Password
                </Link>
              </div>
            </>
          )}
        </>
      );
    } else if (userInfo?.userType === UserTypes.JOB_POSTER) {
      return (
        <>
          <div className="sidebar_list_item">
            <Link
              to="/photographers"
              className={`items-center ${
                pathName === "/photographers" ? "-is-active" : ""
              }`}
            >
              <i className="flaticon-home mr15"></i>Home
            </Link>
          </div>
          {userInfo?.profileStatus === Status.COMPLETED && (
            <>
              <div className="sidebar_list_item">
                <Link
                  to="/create-job"
                  className={`items-center ${
                    pathName === "/create-job" ? "-is-active" : ""
                  }`}
                >
                  <i className="flaticon-content mr15"></i>Post A Job
                </Link>
              </div>
              <div className="sidebar_list_item">
                <Link
                  to="/my-jobs"
                  className={`items-center ${
                    pathName === "/my-jobs" ? "-is-active" : ""
                  }`}
                >
                  <i className="flaticon-like mr15"></i>My Jobs
                </Link>
              </div>
              <div className="sidebar_list_item">
                <Link
                  to="/messages"
                  className={`items-center ${
                    pathName === "/messages" ? "-is-active" : ""
                  }`}
                >
                  <i className="flaticon-chat  mr15"></i>
                  Messages
                </Link>
              </div>
              <div className="sidebar_list_item">
                <Link
                  to="/change-password"
                  className={`items-center ${
                    pathName === "/change-password" ? "-is-active" : ""
                  }`}
                >
                  <i className="fa-sharp fa-light fa-gear mr15"></i>
                  Change Password
                </Link>
              </div>
            </>
          )}
        </>
      );
    } else if (userInfo?.userType === UserTypes.ADMIN) {
      return (
        <>
          <div className="sidebar_list_item">
            <Link
              to="/dashboard"
              className={`items-center ${
                pathName === "/dashboard" ? "-is-active" : ""
              }`}
            >
              <i className="flaticon-home mr15"></i>Dashboard
            </Link>
          </div>
          {/* {userInfo?.profileStatus === Status.COMPLETED && (
            <> */}
          <div className="sidebar_list_item">
            <Link
              to="/manage-photographers"
              className={`items-center ${
                pathName === "/manage-photographers" ? "-is-active" : ""
              }`}
            >
              <i className="flaticon-content mr15"></i>Manage Photographers
            </Link>
          </div>
          <div className="sidebar_list_item">
            <Link
              to="/manage-job-posters"
              className={`items-center ${
                pathName === "/manage-job-posters" ? "-is-active" : ""
              }`}
            >
              <i className="flaticon-presentation mr15"></i>Manage Job Posters
            </Link>
          </div>
          <div className="sidebar_list_item">
            <Link
              to="/manage-jobs"
              className={`items-center ${
                pathName === "/manage-jobs" ? "-is-active" : ""
              }`}
            >
              <i className="flaticon-briefcase mr15"></i>Manage Jobs
            </Link>
          </div>
          <div className="sidebar_list_item">
            <Link
              to="/manage-reports"
              className={`items-center ${
                pathName === "/manage-reports" ? "-is-active" : ""
              }`}
            >
              <i className="flaticon-briefcase mr15"></i>Manage Reports
            </Link>
          </div>
          <div className="sidebar_list_item">
            <Link
              to="/manage-queries"
              className={`items-center ${
                pathName === "/manage-queries" ? "-is-active" : ""
              }`}
            >
              <i className="flaticon-briefcase mr15"></i>Manage Queries
            </Link>
          </div>
          <div className="sidebar_list_item">
            <Link
              to="/transactions"
              className={`items-center ${
                pathName === "/transactions" ? "-is-active" : ""
              }`}
            >
              <i className="flaticon-dollar mr15"></i>Transactions
            </Link>
          </div>
          <div className="sidebar_list_item">
            <Link
              to="/change-password"
              className={`items-center ${
                pathName === "/change-password" ? "-is-active" : ""
              }`}
            >
              <i className="fa-sharp fa-light fa-gear mr15"></i>Change Password
            </Link>
          </div>
          {/* </>
          )} */}
        </>
      );
    }
    return null;
  }, [userInfo?.userType, pathName, userInfo?.profileStatus]);

  return (
    <div className="col-lg-12">
      <div className="dashboard_navigationbar d-block d-lg-none">
        <div className="dropdown">
          {/* <button
            onClick={() => setShowSideBar((prev) => !prev)}
            className="dropbtn">
            <i className="fa fa-bars pr10"></i> Dashboard Navigation
          </button> */}
          <ul
            id="myDropdown"
            className={
              showSideBar ? "dropdown-content show" : "dropdown-content"
            }
          >
            {/* <li>
              <p className="fz15 fw400 ff-heading mt30 pl30">Start</p>
            </li> */}
            <div className="dashboard_sidebar_list ml-n3">
              {memoizedMenu}
              <div className="sidebar_list_item">
                <Link
                  to="/profile"
                  className={`items-center ${
                    pathName === "/profile" ? "-is-active" : ""
                  }`}
                >
                  <i className="flaticon-photo mr15"></i> My Profile
                </Link>
              </div>
              <div className="sidebar_list_item">
                <a className="items-center" onClick={logoutUser}>
                  <i className="flaticon-logout mr15"></i>Logout
                </a>
              </div>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MobileSideBar;
