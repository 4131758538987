import React from "react";
import { Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import Select, { createFilter } from "react-select";

export const getFormSelectOptions = (options = []) =>
  options.map((option) => ({ label: option, value: option }));

export const filterSelectOption = (value, options = []) =>
  options.filter((ele) => ele !== value);

const SelectField = ({
  label,
  name = "",
  options = [],
  errors,
  errorMessage = "",
  control,
  handleChange,
  validate = {},
  isClearable = true,
  isLoading = false,
  isMulti = false,
  noOptionsMessage,
  required,
  menuPortalTarget,
  disabled,
  className,
  ...props
}) => {
  return (
    <Form.Group className="mb-3">
      <Form.Label>
        {label}{" "}
        {required && (
          <span className="required-filed">
            <span className="required-field">*</span>
          </span>
        )}
      </Form.Label>
      <Controller
        name={name}
        control={control}
        rules={{
          required: required && errorMessage,
          ...validate,
        }}
        render={({ field: { onChange, value, ref } }) => {
          return (
            <Select
              options={isLoading ? [] : options}
              noOptionsMessage={() => noOptionsMessage}
              className={`text-capitalize custom-select ${
                errors?.[name] ? "custom-select-error" : ""
              } ${className}`}
              onChange={(currentValue) => {
                onChange(currentValue?.value ?? "");
                handleChange?.(currentValue?.value ?? "");
              }}
              value={
                isMulti
                  ? options.filter((option) => value.includes(option.value))
                  : // eslint-disable-next-line eqeqeq
                    options.find((option) => option.value == value) || null // value can be string and number both
              }
              // eslint-disable-next-line eqeqeq
              defaultValue={
                props.defaultValue ??
                options.find((option) => option.value == value)
              } // value can be string and number both
              filterOption={createFilter({
                stringify: (option) => option.label,
              })}
              isClearable={isClearable}
              isLoading={isLoading}
              isMulti={isMulti}
              //   placeholder={`${formatMessage({
              //     id: "select",
              //   })} ${label}`}
              {...props}
              menuPortalTarget={menuPortalTarget || ""}
              isDisabled={disabled}
              ref={ref}
            />
          );
        }}
      />
      {errors?.[name] && (
        <Form.Text className="text-danger">{errors[name].message}</Form.Text>
      )}
    </Form.Group>
  );
};

export default SelectField;
